<template>
  <div class="root-box">
    <div class="condition">
      <el-form ref="form" size="small" label-width="80px" :model="param" inline>
        <el-row>
          <el-col :span="20">
            <el-form-item label="开始日期" prop="startDate">
              <el-date-picker
                value-format="yyyy-MM-dd"
                v-model="param.startDate"
                style="width: 160px"
                type="date"
                placeholder="选择日期"
              ></el-date-picker>
            </el-form-item>

            <el-form-item label="结束日期" prop="endDate">
              <el-date-picker
                value-format="yyyy-MM-dd"
                v-model="param.endDate"
                style="width: 160px"
                type="date"
                placeholder="选择日期"
              ></el-date-picker>
            </el-form-item>

            <el-form-item label="代理编号" prop="screenId">
              <el-input
                v-model="param.screenId"
                clearable
                style="width: 160px"
              />
            </el-form-item>

            <el-form-item label="手机号码" prop="telephone">
              <el-input
                v-model="param.telephone"
                clearable
                style="width: 160px"
              />
            </el-form-item>

            <el-form-item label="状态" prop="pathId">
              <el-select
                v-model="param.state"
                @change="query()"
                style="width: 160px"
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="item in partnerStateOpts"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="4">
            <el-form-item>
              <el-button
                type="primary"
                :size="size"
                style="width: 80px"
                @click="query()"
                >搜索</el-button
              >
              <el-button
                type="primary"
                :size="size"
                style="width: 80px"
                @click="reset"
                >重置</el-button
              >
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div>
      <el-table
        :data="list"
        @row-click="showHb"
        stripe
        max-height="650"
        @sort-change="handleSort"
      >
        <el-table-column label="序号" type="index" width="50"></el-table-column>
        <el-table-column
          prop="screenId"
          label="代理码"
          sortable="custom"
        ></el-table-column>
        <el-table-column prop="type" sortable="custom" label="商户类型">
          <template slot-scope="scope">{{
            scope.row.type | enumTransfer(partnerTypeOpts)
          }}</template>
        </el-table-column>
        <el-table-column prop="mchName" label="商户名称"></el-table-column>
        <el-table-column
          prop="focusTimes"
          sortable="custom"
          label="关注量"
        ></el-table-column>
        <el-table-column
          prop="shareTimes"
          sortable="custom"
          label="分享数"
        ></el-table-column>
        <el-table-column
          prop="openTimes"
          sortable="custom"
          label="分享打开数"
        ></el-table-column>
        <el-table-column prop="received" sortable="custom" label="领取红包">
          <template slot-scope="scope">{{
            scope.row.received | moneyFormat
          }}</template>
        </el-table-column>
        <el-table-column prop="waiting" sortable="custom" label="待领取红包">
          <template slot-scope="scope">{{
            scope.row.waiting | moneyFormat
          }}</template>
        </el-table-column>
        <el-table-column prop="refund" sortable="custom" label="退回红包">
          <template slot-scope="scope">{{
            scope.row.refund | moneyFormat
          }}</template>
        </el-table-column>
        <el-table-column
          prop="orderNum"
          sortable="custom"
          label="下单量"
        ></el-table-column>
        <el-table-column
          prop="finishOrderNum"
          sortable="custom"
          label="成交数量"
        ></el-table-column>
        <el-table-column
          prop="finishPersonNumber"
          sortable="custom"
          label="成交人数"
        ></el-table-column>

        <el-table-column prop="id" label="操作" align="right" width="180">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click.stop="showDetail(scope.row)"
              >代理详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <div class="page">
        <el-pagination
          @current-change="handleCurrentChange"
          background
          @size-change="handleSizeChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 50, 100]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>

      <el-drawer
        title="代理详情："
        size="800px"
        :visible.sync="detailVisible"
        :append-to-body="true"
        direction="ltr"
      >
        <div class="drawer-box">
          <el-form
            :model="form"
            :inline="true"
            label-width="100px"
            size="small"
          >
            <el-form-item label="代理码：">
              <span>{{ form.screenId }}</span>
            </el-form-item>
            <el-form-item label="商户名称：">
              <span>{{ form.mchName }}</span>
            </el-form-item>
            <el-form-item label="代理商类型：">
              <span>{{ form.type | enumTransfer(partnerTypeOpts) }}</span>
            </el-form-item>
            <el-form-item label="行业类型：">
              <span>{{ form.hyType | enumTransfer(partnerHyTypeOpts) }}</span>
            </el-form-item>

            <el-form-item label="受益人姓名：">
              <span>{{ form.partnerName }}</span>
            </el-form-item>
            <el-form-item label="电话号码：">
              <span>{{ form.telephone }}</span>
            </el-form-item>

            <el-form-item label="微信昵称：">
              <span>{{ form.wxName }}</span>
            </el-form-item>

            <el-form-item label="创建时间：">
              <span>{{ form.createTime }}</span>
            </el-form-item>
            <el-form-item label="代理状态：">
              <span>{{ form.state | enumTransfer(partnerStateOpts) }}</span>
            </el-form-item>

            <el-form-item label="备注：">
              <el-tooltip
                v-if="form.remark"
                class="item"
                effect="dark"
                :content="form.remark"
                placement="bottom-end"
              >
                <span style="width: 200px" class="overflow-text">{{
                  form.remark
                }}</span>
              </el-tooltip>
              <span v-else style="width: 200px" class="overflow-text"></span>
            </el-form-item>
          </el-form>
          <el-divider content-position="left"></el-divider>
          <div>
            <div class="commission">
              <span>提成设置：</span>
              <span>
                <el-button
                  type="primary"
                  size="small"
                  width="80"
                  @click="addCommission"
                  plain
                  >新增</el-button
                >
              </span>
            </div>
            <el-table :data="incomeList" stripe max-height="400" size="small">
              <el-table-column label="序号" type="index"></el-table-column>
              <el-table-column label="路线" type="pathId">
                <template slot-scope="scope">
                  <el-select
                    v-model="scope.row.pathId"
                    size="small"
                    :disabled="!!scope.row.id"
                  >
                    <el-option
                      v-for="item in pathOpts"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column label="提成（元）" type="income">
                <template slot-scope="scope">
                  <el-input-number
                    size="small"
                    v-model="scope.row.income"
                    placeholder
                    :min="0"
                  ></el-input-number>
                </template>
              </el-table-column>
              <el-table-column label="状态" type="state">
                <template slot-scope="scope">
                  <el-switch
                    v-model="scope.row.state"
                    :active-value="1"
                    :inactive-value="0"
                  ></el-switch>
                </template>
              </el-table-column>
              <el-table-column label="操作" type="id">
                <template slot-scope="scope">
                  <el-button type="text" @click="saveCommission(scope.row)"
                    >保存</el-button
                  >
                  <el-button type="text" @click="delCommission(scope.row)"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </el-drawer>

      <el-drawer
        title="红包明细："
        size="1500px"
        :visible.sync="hbListVisible"
        :append-to-body="true"
        direction="ltr"
      >
        <div class="drawer-box">
          <el-table :v-loading="loading" :data="hbList" stripe max-height="500">
            <el-table-column
              label="序号"
              type="index"
              width="50"
            ></el-table-column>
            <el-table-column prop="hbDetail.amount" label="红包金额">
              <template slot-scope="scope" v-if="scope.row.hbDetail">{{
                scope.row.hbDetail.amount | moneyFormat
              }}</template>
            </el-table-column>

            <el-table-column
              prop="hbDetail.createTime"
              label="红包日期"
              width="180"
            ></el-table-column>
            <el-table-column prop="hbDetail.hbType" label="红包类型">
              <template slot-scope="scope" v-if="scope.row.hbDetail">{{
                scope.row.hbDetail.hbType | enumTransfer(hbTypeOpts)
              }}</template>
            </el-table-column>
            <el-table-column prop="hbDetail.hbStatus" label="红包状态">
              <template slot-scope="scope" v-if="scope.row.hbDetail">{{
                scope.row.hbDetail.hbStatus | enumTransfer(hbStatusOpts)
              }}</template>
            </el-table-column>
            <el-table-column prop="hbDetail.state" label="是否已发送">
              <template slot-scope="scope" v-if="scope.row.hbDetail">{{
                scope.row.hbDetail.state ? "已发送" : "未发送"
              }}</template>
            </el-table-column>

            <el-table-column prop="order.pathId" label="路线">
              <template v-if="scope.row.order" slot-scope="scope">{{
                scope.row.order.pathId | enumTransfer(pathOpts)
              }}</template>
            </el-table-column>
            <el-table-column prop="order.date" label="日期"></el-table-column>
            <el-table-column
              prop="order.showTime"
              label="时间"
            ></el-table-column>
            <el-table-column
              prop="order.telephone"
              label="手机"
            ></el-table-column>
            <el-table-column prop="order.number" label="人数"></el-table-column>
            <el-table-column prop="order.price" label="价格"></el-table-column>
            <el-table-column prop="order.orderStatus" label="订单状态">
              <template slot-scope="scope" v-if="scope.row.order">{{
                scope.row.order.orderStatus | enumTransfer(orderStatusOpts)
              }}</template>
            </el-table-column>
          </el-table>

          <div class="page">
            <el-pagination
              @current-change="handleHbCurrentChange"
              background
              :current-page="hbCurrentPage"
              :page-size="10"
              layout="total, prev, pager, next, jumper"
              :total="hbTotal"
            ></el-pagination>
          </div>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import {getPartnerRankList, getPartnerHbList, getPartnerDetail,
  updatePartnerIncome, deletePartnerIncome } from '@/api';
import {ParamUtil, Toolkit} from '@/util';
import {mapState} from 'vuex';

export default {
  created () {

    this.query();
  },

  computed: {
    ...mapState({
      size: state => state.btnSize,
      orderStatusOpts: state => state.enumMap['ORDER_STATUS'],
      hbTypeOpts: state => state.enumMap['PARTNER_HB_TYPE'],
      hbStatusOpts: state => state.enumMap['WX_HB_STATUS'],
      partnerHyTypeOpts: state => state.enumMap['PARTNER_HY_TYPE'],
      partnerTypeOpts: state => state.enumMap['PARTNER_TYPE'],
      partnerStateOpts: state => state.enumMap['PARTNER_STATE'],
      pathOpts: state =>{
        const pathList = state.pathList;
        return pathList.map(item=>{
          return {
            label: item.name,
            value: item.id
          };
        });
      },
    })
  },


  data () {
    return {
      param: {},
      list: [],
      total: 0,
      currentPage: 1,
      hbList: [],
      hbTotal: 0,
      hbCurrentPage: 1,
      asc: false,
      column: '',
      hbListVisible: false,
      detailVisible: false,
      loading: false,
      form: {},
      incomeList: [],
      from: {},

    };
  },
  methods: {
    reset () {
      this.$refs.form.resetFields();
    },
    query (page = 1, pageSize = 10) {
      const param = {
        page: {
          current: page,
          size: pageSize
        },
        param: ParamUtil.filterEmptyData(this.param),
        startTime: this.param.startDate,
        endTime: this.param.endDate,
      };
      if (this.column) {
        param.page.orders = [{
          column: this.column,
          asc: this.asc,
        }];
      }
      getPartnerRankList(param).then(res=>{
        const {records = [], current = 1, total = 0} = res.data || {};
        this.list = records;
        this.currentPage = current;
        this.total = total;
        this.hbList = [];
        this.hbCurrentPage = 1;
        this.hbTotal = 0;
      });
    },
    saveCommission (item) {
      updatePartnerIncome(item).then(()=>{
        this.$message.success('更新成功');
        this.queryPartnerDetail();
      });
    },
    addCommission () {
      this.incomeList.push({
        partnerId: this.currentSelect.id,
        state: 0
      });
    },
    delCommission (item) {
      if (item.id) {
        this.$confirm("确认删除吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          deletePartnerIncome(item).then(()=>{
            this.$message.success('删除成功');
            this.queryPartnerDetail();
          });
        }, ()=>{});
      } else {
        const incomeList = this.incomeList.filter(t=> t.id != item.id);
        this.incomeList = incomeList;
      }
    },
    handleCurrentChange (page) {
      this.query(page);
    },
    handleHbCurrentChange (page) {
      this.queryHbList(page);
    },
    handleSizeChange (pageSize) {
      this.currentPage = 1;
      this.query(1, pageSize);
    },
    handleSort (item) {
      if (item.order) {
        this.asc = item.order.indexOf('asc') > -1;
        this.column = Toolkit.toLine(item.prop);
        this.query();
      } else {
        this.column = null;
      }

    },
    showHb (item) {
      this.hbListVisible = true;
      this.chooseItem = item;
      this.queryHbList();
    },

    showDetail (item) {
      this.detailVisible = true;
      this.currentSelect = item;
      this.queryPartnerDetail();
    },

    queryPartnerDetail () {
      if (this.currentSelect) {
        getPartnerDetail(this.currentSelect.id).then(res=>{
          const {partner, incomeList = []} = res.data || {};
          this.incomeList = incomeList;
          this.form = partner;
        });
      }
    },
    queryHbList (page = 1, pageSize = 10) {
      const param = {
        page: {
          current: page,
          size: pageSize
        },
        param: {partnerId: this.chooseItem.id},
        startTime: this.param.startDate,
        endTime: this.param.endDate,

      };

      this.loading = true;
      getPartnerHbList(ParamUtil.filterEmptyData(param)).then(res=>{
        this.loading = false;
        const {records = [], current = 1, total = 0} = res.data || {};
        this.hbList = records;
        this.hbCurrentPage = current;
        this.hbTotal = total;

      });
    }
  },
};
</script>
<style lang="less" scoped>
.hb-detail {
  font-size: 14px;
  margin: 5px;
  font-weight: bold;
  color: brown;
}

.drawer-box {
  margin: 20px;
  min-height: 400px;

  /deep/ .el-form-item__content {
    width: 200px;
  }

  .el-form-item {
    span {
      height: 32px;
      line-height: 32px;
      border-radius: 4px;
      border: 1px solid #dcdfe6;
      box-sizing: border-box;
      display: inline-block;
      font-size: inherit;
      outline: 0;
      padding: 0 15px;
      transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      width: 200px;
      background-color: #f5f7fa;
      font-size: 12px;
      color: #444343;
    }
  }

  .commission {
    margin: 20px 5px;
    color: #72767b;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
  }
}
</style>