<template>
  <div class="root">
    <div class="query-container" style="padding-bottom: 10px">
      <el-input
        placeholder="司机编号\手机号\车牌号"
        v-model="keyword"
        style="width: 200px; margin-right: 6px"
      ></el-input>
      <el-date-picker
        v-model="startDate"
        type="date"
        value-format="yyyy-MM-dd"
        placeholder="选择起始日期"
      >
      </el-date-picker>
      至
      <el-date-picker
        v-model="endDate"
        type="date"
        value-format="yyyy-MM-dd"
        placeholder="选择截至日期"
      >
      </el-date-picker>
      <el-button @click="query" style="margin-left: 6px">查询</el-button>
    </div>

    <div class="car-info">
      <div>{{ carInfo.vehicleNo }}</div>
      <div>{{ carInfo.color + carInfo.brand }}</div>
      <div>渠道：{{ carInfo.source }}</div>
      <div>起租日期：{{ carInfo.rentStartDate || "--" }}</div>
      <div>绑定日期：{{ carInfo.bindDate }}</div>
      <div>当前司机：{{ (carInfo.driverNo || "") + " " + (carInfo.driverName || "") }}</div>
      <div>累计服务费: {{ carInfo.amount ? carInfo.amount / 100 : 0 }}元</div>
      <div>最近里程数： {{ carInfo.miles }}</div>
    </div>

    <div class="car-card">
      <el-card
        shadow="always"
        @click.native="chooseCard('inspection')"
        :class="serviceType == 'inspection' ? 'active' : ''"
      >
        <div>检查</div>
        <div>
          {{ carInfo.inspectionCnt || 0 }}次 -
          {{ carInfo.inspectionAmount ? carInfo.inspectionAmount / 100 : 0 }}元
        </div>
      </el-card>
      <el-card
        shadow="always"
        @click.native="chooseCard('repair')"
        :class="serviceType == 'repair' ? 'active' : ''"
      >
        <div>维修</div>
        <div>
          {{ carInfo.repairCnt || 0 }}次 -
          {{ carInfo.repairAmount ? carInfo.repairAmount / 100 : 0 }}元
        </div>
      </el-card>
      <el-card
        shadow="always"
        @click.native="chooseCard('maintenance')"
        :class="serviceType == 'maintenance' ? 'active' : ''"
      >
        <div>保养</div>
        <div>
          {{ carInfo.mtcCnt || 0 }}次 - {{ carInfo.mtcAmount ? carInfo.mtcAmount / 100 : 0 }}元
        </div>
      </el-card>
      <el-card
        shadow="always"
        @click.native="chooseCard('wash')"
        :class="serviceType == 'wash' ? 'active' : ''"
      >
        <div>洗车</div>
        <div>
          {{ carInfo.washCnt || 0 }}次 - {{ carInfo.washAmount ? carInfo.washAmount / 100 : 0 }}元
        </div>
      </el-card>
      <el-card
          shadow="always"
          @click.native="chooseCard('vme')"
          :class="serviceType == 'vme' ? 'active' : ''"
        >
          <div>车载维护</div>
          <div>
            {{ carInfo.vmeCnt || 0 }}次 - {{ carInfo.vmeAmount ? carInfo.vmeAmount / 100 : 0 }}元
          </div>
      </el-card>
      <el-card
        shadow="always"
        @click.native="chooseCard('assess')"
        :class="serviceType == 'assess' ? 'active' : ''"
      >
        <div>定损</div>
        <div>
          {{ carInfo.assessCnt || 0 }}次 -
          {{ carInfo.assessAmount ? carInfo.assessAmount / 100 : 0 }}元
        </div>
      </el-card>
    </div>

    <div v-if="carInfo.vehicleId">
      <div v-if="serviceType == 'inspection'">
        <el-link type="primary" @click="createBill" style="margin-left: 6px">创建检查单</el-link>

        当前检查周期：{{ carInfo.inspectionPeriod }}天 &nbsp;&nbsp;&nbsp; 下次检查日期：{{
          carInfo.nextInspectionTime && carInfo.nextInspectionTime.substring(0, 10)
        }}
        <i class="el-icon-edit" @click="setServicePeriod" style="cursor: pointer"></i>
      </div>
      <div v-if="serviceType == 'repair'">
        <el-link type="primary" @click="createBill" style="margin-left: 6px">创建维修单</el-link>
      </div>
      <div v-if="serviceType == 'maintenance'">
        <el-link type="primary" @click="createBill" style="margin-left: 6px">创建保养单</el-link>

        当前保养周期：{{ carInfo.mtcPeriod }}天 &nbsp;&nbsp;&nbsp; 下次保养日期：{{
          carInfo.nextMtcTime && carInfo.nextMtcTime.substring(0, 10)
        }}
        <i class="el-icon-edit" @click="setServicePeriod" style="cursor: pointer"></i>
      </div>
      <div v-if="serviceType == 'wash'">
        <el-link type="primary" @click="createBill" style="margin-left: 6px">创建洗车单</el-link>

        当前汽车周期：{{ carInfo.washPeriod }}天 &nbsp;&nbsp;&nbsp; 下次洗车日期：{{
          carInfo.nextWashTime && carInfo.nextWashTime.substring(0, 10)
        }}
        <i class="el-icon-edit" @click="setServicePeriod" style="cursor: pointer"></i>
      </div>
      <div v-if="serviceType == 'vme'">
        <el-link type="primary" @click="createBill" style="margin-left: 6px">创建车载维护单</el-link>
      </div>
      <div v-if="serviceType == 'assess'">
        <el-link type="primary" @click="createBill" style="margin-left: 6px">创建定损单</el-link>
      </div>
    </div>

    <div class="table">
      <el-table :data="carInfo.svcBills" style="width: 100%">
        <el-table-column prop="serviceDate" label="日期">
          <template slot-scope="scope">
            {{ scope.row.serviceDate.substring(0, 16) }}
          </template>
        </el-table-column>
        <el-table-column prop="providerName" label="服务商"> </el-table-column>
        <el-table-column prop="staffName" label="检修人"> </el-table-column>
        <el-table-column prop="amount" label="费用">
          <template slot-scope="scope">
            {{ scope.row.amount ? scope.row.amount / 100 + "元" : 0 }}
          </template>
        </el-table-column>
        <el-table-column label="状态">
          <template slot-scope="scope">
            {{ getBillStatusLabel(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column prop="miles" label="里程数"> </el-table-column>
        <el-table-column label="审核">
          <template slot-scope="scope">
            {{ getBillAuditLabel(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-link type="primary" v-if="scope.row.status == 0" @click="serviceBill(scope.row)"
              >送检</el-link
            >
            <el-link type="primary" v-else @click="viewBill(scope.row.id)">详情</el-link>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog :visible.sync="showDialog" title="设置服务周期">
      <el-form ref="form" label-width="100px">
        <el-form-item label="下次服务时间" prop="name">
          <el-date-picker
            v-model="serviceDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择服务时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="服务周期" prop="servicePeriod">
          <el-input v-model="servicePeriod" placeholder="输入周期天数"></el-input>
        </el-form-item>
        <el-form-item label="默认费用" prop="washFee" v-if="serviceType == 'wash'">
          <el-input v-model="washFee" placeholder="输入服务金额（单位：分）"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="saveServicePeriod">提交</el-button>
        <el-button @click="showDialog = false">取消</el-button>
      </span>
    </el-dialog>

    <!-- 侧边栏 -->
    <el-dialog title="单据详情" :visible.sync="showBillDialog">
      <billDetail :bill-id="currentBillId" v-if="!!currentBillId"></billDetail>

      <span slot="footer" class="dialog-footer">
        <el-button @click="(showBillDialog = false), (currentBillId = null)">关闭</el-button>
      </span>
    </el-dialog>

    <el-dialog :visible.sync="showCreateDialog">
      <create-bill
        :vehicleInfo="carInfo"
        :serviceType="serviceType"
        @billChange="saveBill"
        v-if="refresh"
      ></create-bill>
    </el-dialog>
  </div>
</template>

<script>
import {
  listVehicleInfo,
  saveVehiclePeriodInfo,
  createServiceBillInfo,
  toService,
} from "@/api/partner_admin.js";
import { DateUtil } from "@/util";
import createBill from "./create-bill";
import billDetail from "../bill_detail";

export default {
  name: "index",
  data() {
    return {
      keyword: null,
      startDate: null,
      endDate: null,
      showDialog: false,
      showCreateDialog: false,
      showBillDialog: false,
      serviceType: "inspection",
      refresh: false,
      serviceDate: null,
      servicePeriod: null,
      washFee: null,
      currentBillId: null,
      carInfo: {
        vehicleId: null,
        vehicleNo: null,
        color: null,
        brand: null,
        source: null,
        rentStartDate: null,
        driverId: null,
        driverNo: null,
        driverName: null,
        amount: 0,
        miles: 0,
        inspectionCnt: 0,
        inspectionAmount: 0,
        washCnt: 0,
        washAmount: 0,
        vmeCnt: 0,
        vmeAmount: 0,
        mtcCnt: 0,
        mtcAmount: 0,
        repairCtn: 0,
        repairAmount: 0,
        assessCnt: 0,
        assessAmount: 0,
        bills: [],
        svcBills: [],
      },
    };
  },
  components: {
    createBill,
    billDetail,
  },
  mounted() {
    this.initPage();
  },
  methods: {
    initPage() {
      this.startDate = DateUtil.format(new Date(new Date().getTime() - 7 * 24 * 3600 * 1000));
      this.endDate = DateUtil.format(new Date());
    },
    query() {
      if (!this.keyword) {
        this.$message.error("司机编号\\手机号\\车牌号不能为空。");
        return;
      }
      listVehicleInfo(this.keyword, this.startDate, this.endDate).then((res) => {
        this.carInfo = res.data || {};
        this.chooseCard(this.serviceType);
      });
    },
    chooseCard(serviceType) {
      this.serviceType = serviceType;
      if (serviceType == "inspection") {
        this.serviceDate = this.carInfo.nextInspectionTime;
        this.servicePeriod = this.carInfo.inspectionPeriod;
      } else if (serviceType == "wash") {
        this.serviceDate = this.carInfo.nextWashTime;
        this.servicePeriod = this.carInfo.washPeriod;
        this.washFee = this.carInfo.washFee;
      } else if (serviceType == "maintenance") {
        this.serviceDate = this.carInfo.nextMtcTime;
        this.servicePeriod = this.carInfo.mtcPeriod;
      } else {
        this.serviceDate = null;
        this.servicePeriod = null;
      }
      let svcBills = [];
      if (this.carInfo.bills) {
        svcBills = this.carInfo.bills.filter((bill) => bill.serviceType == serviceType);
      }
      this.carInfo.svcBills = svcBills;
    },
    getBillStatusLabel(bill) {
      return bill.status < 10 ? "待办" : "已办";
    },
    getBillAuditLabel(bill) {
      if (bill.status < 10) {
        return "-";
      } else if (bill.status == 31) {
        return "不通过";
      } else if (bill.status == 30) {
        return "通过";
      } else {
        return "待审";
      }
    },
    setServicePeriod() {
      this.showDialog = true;
    },
    saveServicePeriod() {
      let params = {
        vehicleNo: this.carInfo.vehicleNo,
        serviceType: this.serviceType,
        serviceDate: this.serviceDate,
        servicePeriod: this.servicePeriod,
        washFee: this.washFee
      };
      saveVehiclePeriodInfo(params).then(() => {
        if (this.serviceType == "inspection") {
          this.carInfo.nextInspectionTime = this.serviceDate;
          this.carInfo.inspectionPeriod = this.servicePeriod;
        } else if (this.serviceType == "wash") {
          this.carInfo.nextWashTime = this.serviceDate;
          this.carInfo.washPeriod = this.servicePeriod;
          this.carInfo.washFee = this.washFee;
        } else if (this.serviceType == "maintenance") {
          this.carInfo.nextMtcTime = this.serviceDate;
          this.carInfo.mtcPeriod = this.servicePeriod;
        }
        this.$message.success("设置成功。");
        this.showDialog = false;
      });
    },
    createBill() {
      this.showCreateDialog = true;
      this.refresh = true;
    },
    saveBill(param) {
      let bill = param.bill;
      let action = param.action;
      if (action == "save") {
        createServiceBillInfo(bill).then((res) => {
          this.showCreateDialog = false;
          this.refresh = false;
          let bill = res.data;
          if (!this.carInfo.bills) {
            this.carInfo.bills = [];
          }
          this.carInfo.bills.push(bill);
          this.carInfo.svcBills = this.carInfo.bills.filter(
            (bill) => bill.serviceType == this.serviceType
          );
          this.$message.success("创建单据成功。");
        });
      } else {
        this.showCreateDialog = false;
        this.refresh = false;
      }
    },
    serviceBill(bill) {
      this.$confirm("确定要送检吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        toService(bill.id).then(() => {
          bill.status = 2;
          this.$message.success("送检成功。");
        });
      });
    },
    viewBill(billId) {
      this.currentBillId = null;
      this.$nextTick(() => {
        this.currentBillId = billId;
        this.showBillDialog = true;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.root {
  padding: 10px;

  .el-dialog {
    height: 745px;
    overflow-y: scroll;
  }

  .car-info {
    & > div {
      display: inline-block;
      padding-right: 10px;
    }
  }
  .car-card {
    .el-card {
      width: 200px;
      display: inline-block;
      margin-right: 10px;
      text-align: center;

      &.active {
        background-color: #4894ef;
        color: #fff;
      }
    }
  }
}
</style>
