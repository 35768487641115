<template>
  <div class="root-box"></div>
</template>

<script>
export default {
  components: {},
  props: {},
  created () {},
  mounted () {},
  watch: {},
  computed: {},
  data () {
    return {
    };
  },
  methods: {},
};
</script>
<style lang="less" scoped>
</style>