<template>
  <div class="root-box">
    <div class="condition">
      <el-form ref="form" size="small" label-width="80px" :model="param" inline>
        <el-row>
          <el-col :span="20">
            <el-form-item label="代理编号" prop="screenId">
              <el-input
                v-model="param.screenId"
                clearable
                style="width: 160px"
              />
            </el-form-item>

            <el-form-item label="手机号码" prop="telephone">
              <el-input
                v-model="param.telephone"
                clearable
                style="width: 160px"
              />
            </el-form-item>

            <el-form-item label="商户类型" prop="pathId">
              <el-select
                v-model="param.type"
                @change="query()"
                style="width: 160px"
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="item in partnerTypeOpts"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="状态" prop="pathId">
              <el-select
                v-model="param.state"
                @change="query()"
                style="width: 160px"
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="item in partnerStateOpts"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="4">
            <el-form-item>
              <el-button
                type="primary"
                :size="size"
                style="width: 80px"
                @click="query()"
                >搜索</el-button
              >
              <el-button
                type="primary"
                :size="size"
                style="width: 80px"
                @click="reset"
                >重置</el-button
              >
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div>
      <el-table :data="list" stripe max-height="650" @sort-change="handleSort">
        <el-table-column label="序号" type="index" width="50"></el-table-column>
        <el-table-column
          prop="screenId"
          sortable="custom"
          label="代理码"
        ></el-table-column>
        <el-table-column prop="mchName" label="商户名称"></el-table-column>
        <el-table-column
          prop="partnerName"
          label="受益人姓名"
        ></el-table-column>
        <el-table-column prop="telephone" label="手机"></el-table-column>
        <el-table-column prop="wxName" label="微信昵称"></el-table-column>

        <el-table-column prop="type" sortable="custom" label="商户类型">
          <template slot-scope="scope">{{
            scope.row.type | enumTransfer(partnerTypeOpts)
          }}</template>
        </el-table-column>
        <el-table-column prop="hyType" sortable="custom" label="行业类型">
          <template slot-scope="scope">{{
            scope.row.hyType | enumTransfer(partnerHyTypeOpts)
          }}</template>
        </el-table-column>
        <el-table-column prop="type" sortable="custom" label="状态">
          <template slot-scope="scope">{{
            scope.row.state | enumTransfer(partnerStateOpts)
          }}</template>
        </el-table-column>
        <el-table-column
          prop="remark"
          show-overflow-tooltip
          label="备注"
          width="150"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="创建时间"
          sortable="custom"
          width="160"
        ></el-table-column>

        <el-table-column prop="id" label="操作" align="right" width="180">
          <template slot-scope="scope">
            <span v-if="scope.row.state === 3">
              <el-button type="text" size="small" @click.stop="pass(scope.row)"
                >审核通过</el-button
              >
              <el-button type="text" size="small" @click.stop="del(scope.row)"
                >删除</el-button
              >
              <el-button
                type="text"
                size="small"
                @click.stop="modify(scope.row)"
                >修改</el-button
              >
            </span>
            <span v-else>
              <el-button
                type="text"
                size="small"
                :disabled="scope.row.state !== 1"
                @click.stop="showDetail(scope.row)"
                >提成设置</el-button
              >
              <el-button
                v-if="scope.row.state === 1"
                type="text"
                size="small"
                @click.stop="changeState(scope.row)"
                >封禁</el-button
              >
              <el-button
                v-else
                type="text"
                size="small"
                @click.stop="changeState(scope.row)"
                >解封</el-button
              >
              <el-button
                type="text"
                size="small"
                @click.stop="modify(scope.row)"
                >修改</el-button
              >
            </span>
          </template>
        </el-table-column>
      </el-table>

      <div class="page">
        <el-pagination
          @current-change="handleCurrentChange"
          background
          @size-change="handleSizeChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 50, 100]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>

      <el-drawer
        title="代理详情："
        size="800px"
        :visible.sync="detailVisible"
        :append-to-body="true"
        direction="ltr"
      >
        <div class="drawer-box">
          <el-form
            :model="form"
            :inline="true"
            label-width="100px"
            size="small"
          >
            <el-form-item label="代理码：">
              <span>{{ form.screenId }}</span>
            </el-form-item>
            <el-form-item label="商户名称：">
              <span>{{ form.mchName }}</span>
            </el-form-item>
            <el-form-item label="代理商类型：">
              <span>{{ form.type | enumTransfer(partnerTypeOpts) }}</span>
            </el-form-item>
            <el-form-item label="行业类型：">
              <span>{{ form.hyType | enumTransfer(partnerHyTypeOpts) }}</span>
            </el-form-item>

            <el-form-item label="受益人姓名：">
              <span>{{ form.partnerName }}</span>
            </el-form-item>
            <el-form-item label="电话号码：">
              <span>{{ form.telephone }}</span>
            </el-form-item>

            <el-form-item label="微信昵称：">
              <span>{{ form.wxName }}</span>
            </el-form-item>

            <el-form-item label="创建时间：">
              <span>{{ form.createTime }}</span>
            </el-form-item>
            <el-form-item label="代理状态：">
              <span>{{ form.state | enumTransfer(partnerStateOpts) }}</span>
            </el-form-item>

            <el-form-item label="备注：">
              <el-tooltip
                v-if="form.remark"
                class="item"
                effect="dark"
                :content="form.remark"
                placement="bottom-end"
              >
                <span style="width: 200px" class="overflow-text">{{
                  form.remark
                }}</span>
              </el-tooltip>
              <span v-else style="width: 200px" class="overflow-text"></span>
            </el-form-item>
          </el-form>

          <el-divider content-position="left"></el-divider>
          <div>
            <div class="commission">
              <span>提成设置：</span>
              <span>
                <el-button
                  type="primary"
                  size="small"
                  width="80"
                  @click="addCommission"
                  plain
                  >新增</el-button
                >
              </span>
            </div>
            <el-table :data="incomeList" stripe max-height="400" size="small">
              <el-table-column label="序号" type="index"></el-table-column>
              <el-table-column label="路线" type="pathId">
                <template slot-scope="scope">
                  <el-select
                    v-model="scope.row.pathId"
                    size="small"
                    :disabled="!!scope.row.id"
                  >
                    <el-option
                      v-for="item in pathOpts"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column label="提成（元）" type="income">
                <template slot-scope="scope">
                  <el-input-number
                    size="small"
                    v-model="scope.row.income"
                    placeholder
                    :min="0"
                  ></el-input-number>
                </template>
              </el-table-column>
              <el-table-column label="状态" type="state">
                <template slot-scope="scope">
                  <el-switch
                    v-model="scope.row.state"
                    :active-value="1"
                    :inactive-value="0"
                  ></el-switch>
                </template>
              </el-table-column>
              <el-table-column label="操作" type="id">
                <template slot-scope="scope">
                  <el-button type="text" @click="saveCommission(scope.row)"
                    >保存</el-button
                  >
                  <el-button type="text" @click="delCommission(scope.row)"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </el-drawer>
    </div>

    <el-dialog
      title="修改代理信息"
      :visible.sync="dialogVisible"
      width="25%"
      :close-on-click-modal="false"
      :destroy-on-close="true"
    >
      <el-form
        ref="partnerForm"
        :model="partnerForm"
        :size="size"
        label-width="120px"
        :rules="rules"
      >
        <el-form-item label="代理码" prop="screenId">
          <el-input
            v-model="partnerForm.screenId"
            :disabled="partnerForm.state !== 3"
            style="width: 215px"
          ></el-input>
        </el-form-item>

        <el-form-item label="手机" prop="telephone">
          <el-input
            v-model="partnerForm.telephone"
            style="width: 215px"
          ></el-input>
        </el-form-item>

        <el-form-item label="商户类型" prop="type">
          <el-select v-model="partnerForm.type" placeholder="请选择">
            <el-option
              v-for="item in partnerTypeOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="行业类型" prop="hyType">
          <el-select v-model="partnerForm.hyType" placeholder="请选择">
            <el-option
              v-for="item in partnerHyTypeOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="备注" prop="remark">
          <el-input
            type="textarea"
            v-model="partnerForm.remark"
            style="width: 215px"
          />
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button :size="size" @click="dialogVisible = false">取 消</el-button>
        <el-button :size="size" type="primary" @click="updatePartner"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {passPartner, getPartnerList, removePartner, getPartnerDetail, updatePartner,
  banPartner, unbanPartner, updatePartnerIncome, deletePartnerIncome, } from '@/api';
import {mapState} from 'vuex';
import {ParamUtil, Toolkit} from '@/util';
import _ from 'lodash';

export default {
  components: {},
  props: {},
  created () {
    this.query();
  },
  mounted () {},
  watch: {},
  computed: {
    ...mapState({
      size: state => state.btnSize,
      partnerHyTypeOpts: state => state.enumMap['PARTNER_HY_TYPE'],
      partnerTypeOpts: state => state.enumMap['PARTNER_TYPE'],
      partnerStateOpts: state => state.enumMap['PARTNER_STATE'],
      pathOpts: state =>{
        const pathList = state.pathList;
        return pathList.map(item=>{
          return {
            label: item.name,
            value: item.id
          };
        });
      },
    })
  },
  data () {
    return {
      param: {},
      list: [],
      detailVisible: false,
      form: {},
      incomeList: [],
      total: 0,
      currentPage: 1,
      asc: false,
      column: '',
      dialogVisible: false,
      partnerForm: {},
      rules: {
        type: [
          {required: true, message: '请选择代理类型', trigger: 'blur'},
        ],
        hyType: [
          {required: true, message: '请选择代理行业类型', trigger: 'blur'},
        ],
        telephone: [
          {required: true, message: '请输入手机号', trigger: 'blur'},
          {pattern: /^1\d{10}$/, message: '请输入正确的手机号'}
        ],
      }
    };
  },
  methods: {
    reset () {
      this.$refs.form.resetFields();
    },
    query (page = 1, pageSize = 10) {
      const param = {
        page: {
          current: page,
          size: pageSize
        },
        param: ParamUtil.filterEmptyData(this.param),
      };
      if (this.column) {
        param.page.orders = [{
          column: this.column,
          asc: this.asc,
        }];
      }
      getPartnerList(param).then(res=>{
        const {records = [], current = 1, total = 0} = res.data || {};
        this.list = records;
        this.currentPage = current;
        this.total = total;
      });
    },
    handleSort (item) {
      if (item.order) {
        this.asc = item.order.indexOf('asc') > -1;
        this.column = Toolkit.toLine(item.prop);
        this.query();
      } else {
        this.column = null;
      }

    },
    modify (item) {
      this.partnerForm = _.cloneDeep(item);
      this.dialogVisible = true;
      this.currentSelect = item;
    },
    handleCurrentChange (page) {
      this.query(page);
    },
    handleSizeChange (pageSize) {
      this.currentPage = 1;
      this.query(1, pageSize);
    },
    showDetail (item) {
      this.detailVisible = true;
      this.currentSelect = item;
      this.queryPartnerDetail();
    },

    updatePartner () {

      this.$refs.partnerForm.validate((valid) => {
        if (!valid) {
          return;
        }
        updatePartner(this.partnerForm).then(()=>{
          this.$message.success('更新成功');
          this.dialogVisible = false;
          this.query();
        });
      });
    },
    pass (item) {
      this.$confirm("确认审核通过吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        passPartner(item.id).then(()=>{
          this.query();
          this.$alert('审核通过，请注意设置提成金额', '注意', {
            confirmButtonText: '确定',
            type: 'success'
          });

        });
      }, ()=>{});
    },
    del (item) {
      this.$confirm("确认删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        removePartner(item.id).then(()=>{
          this.$message.success('删除成功');
          this.query();
        });
      }, ()=>{});
    },
    queryPartnerDetail () {
      if (this.currentSelect) {
        getPartnerDetail(this.currentSelect.id).then(res=>{
          const {partner, incomeList = []} = res.data || {};
          this.incomeList = incomeList;
          this.form = partner;
        });
      }
    },
    changeState (item) {
      if (item.state === 1) {
        this.ban(item);
      } else {
        this.unban(item);
      }
    },
    ban (item) {
      this.$confirm("封禁后代理将停止所有提成，确认封禁吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        banPartner(item.id).then(()=>{
          this.$message.success('封禁成功');
          this.query();
        });
      }, ()=>{});
    },
    unban (item) {
      this.$confirm("注意，解封后提成需要手动开启才能生效！", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        unbanPartner(item.id).then(()=>{
          this.$message.success('解封成功');
          this.query();
        });
      }, ()=>{});
    },
    saveCommission (item) {
      updatePartnerIncome(item).then(()=>{
        this.$message.success('更新成功');
        this.queryPartnerDetail();
      });
    },
    addCommission () {
      this.incomeList.push({
        partnerId: this.currentSelect.id,
        state: 0
      });
    },
    delCommission (item) {
      if (item.id) {
        this.$confirm("确认删除吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          deletePartnerIncome(item).then(()=>{
            this.$message.success('删除成功');
            this.queryPartnerDetail();
          });
        }, ()=>{});
      } else {
        const incomeList = this.incomeList.filter(t=> t.id != item.id);
        this.incomeList = incomeList;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.drawer-box {
  margin: 20px;
  min-height: 400px;

  /deep/ .el-form-item__content {
    width: 200px;
  }

  .el-form-item {
    span {
      height: 32px;
      line-height: 32px;
      border-radius: 4px;
      border: 1px solid #dcdfe6;
      box-sizing: border-box;
      display: inline-block;
      font-size: inherit;
      outline: 0;
      padding: 0 15px;
      transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      width: 200px;
      background-color: #f5f7fa;
      font-size: 12px;
      color: #444343;
    }
  }

  .commission {
    margin: 20px 5px;
    color: #72767b;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
  }
}
</style>