<template>
  <div class="root-box">
    <div class="query-container space-between">
      <div>
        <el-form size="small" :inline="true">
          <el-form-item label="路线：" prop="pathId">
            <el-select
              v-model="param.pathId"
              placeholder="请选择"
              @change="query"
              clearable
            >
              <el-option
                v-for="item in pathOpts"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="启用状态：" prop="state">
            <el-select
              v-model="param.state"
              placeholder="请选择"
              @change="query"
              clearable
            >
              <el-option
                v-for="item in yesNoOpts"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button
              style="width: 100px; margin-right: 20px"
              type="primary"
              @click="query"
              >查询</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div>
        <el-button
          type="primary"
          style="width: 100px"
          :size="size"
          @click="openAdd"
          >新增早价规则</el-button
        >
      </div>
    </div>

    <el-table :data="list" stripe max-height="600">
      <el-table-column label="序号" type="index" width="100"></el-table-column>

      <el-table-column prop="pathId" label="路线名称">
        <template slot-scope="scope">
          <div>{{ scope.row.pathId | pathNameTransfer }}</div>
        </template>
      </el-table-column>

      <el-table-column
        prop="aheadTime1"
        label="提前时间1（分钟）"
      ></el-table-column>
      <el-table-column prop="discount1" label="优惠金额1"></el-table-column>

      <el-table-column
        prop="aheadTime2"
        label="提前时间2（分钟）"
      ></el-table-column>
      <el-table-column prop="discount2" label="优惠金额2"></el-table-column>

      <el-table-column prop="state" label="是否启用">
        <template slot-scope="scope">{{
          scope.row.state | enumTransfer(yesNoOpts)
        }}</template>
      </el-table-column>

      <el-table-column prop="address" label="操作">
        <template slot-scope="scope">
          <span>
            <el-button type="text" size="small" @click="openEdit(scope.row)"
              >修改</el-button
            >

            <el-button type="text" size="small" @click="changeState(scope.row)">
              <span v-if="scope.row.state">停用</span>
              <span v-else class="red">启用</span>
            </el-button>
          </span>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      :visible.sync="visible"
      width="600px"
      max-height="600"
      :size="size"
      :title="title"
      :close-on-click-modal="false"
    >
      <el-form
        ref="form"
        :model="form"
        :size="size"
        label-width="120px"
        :rules="rules"
      >
        <el-form-item label="路线：" prop="pathId">
          <el-select
            v-model="form.pathId"
            :disabled="form.id"
            style="width: 100%"
          >
            <el-option
              v-for="item in pathOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="提前时间1：" prop="aheadTime1">
          <el-input-number
            style="width: 100%"
            v-model="form.aheadTime1"
            placeholder
          ></el-input-number>
        </el-form-item>

        <el-form-item label="优惠金额1：" prop="discount1">
          <el-input-number
            style="width: 100%"
            v-model="form.discount1"
            placeholder
          ></el-input-number>
        </el-form-item>

        <el-form-item label="提前时间2：" prop="aheadTime2">
          <el-input-number
            style="width: 100%"
            v-model="form.aheadTime2"
            placeholder
          ></el-input-number>
        </el-form-item>

        <el-form-item label="优惠金额2：" prop="discount2">
          <el-input-number
            style="width: 100%"
            v-model="form.discount2"
            placeholder
          ></el-input-number>
        </el-form-item>

        <el-form-item label="是否启用：" prop="state">
          <el-select
            v-model="form.state"
            placeholder="请选择"
            style="width: 100%"
            clearable
          >
            <el-option
              v-for="item in yesNoOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button type="warning" :size="size" @click="saveOrUpdate"
          >保存</el-button
        >
        <el-button type="info" :size="size" @click="closeDiag">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {addAheadPriceRule, modifyAheadPriceRule, listAheadPriceRule} from '@/api';
import _ from 'lodash';
import {Toolkit} from '@/util';
import {BaseDataService} from '@/service';
import { mapState } from 'vuex';

const MODE = {
  EDIT: 1,
  ADD: 2
};
export default {
  created () {
    this.query();
  },
  computed: {
    title () {
      return this.mode === MODE.ADD ? '新增早价规则' : '修改早价规则';
    },
    ...mapState({
      size: state => state.btnSize,
      pathOpts: state =>{
        const pathList = state.pathList;
        return pathList.map(item=>{
          return {
            label: item.name,
            value: item.id
          };
        });

      }
    })
  },
  data () {
    return {
      MODE,
      visible: false,
      param: {},
      form: {},
      list: [],
      mode: MODE.EDIT,
      yesNoOpts: BaseDataService.yesNoOpts(),
      rules: {
        pathId: [
          {required: true, message: '路线不能为空', trigger: 'blur'},
        ],
        state: [
          {required: true, message: '是否启用不能为空', trigger: 'blur'},
        ],
        aheadTime1: [
          {required: true, message: '提前时间1不能为空', trigger: 'blur'},
        ],
        discount1: [
          {required: true, message: '优惠金额1不能为空', trigger: 'blur'},
        ],
      }
    };
  },
  methods: {
    closeDiag () {
      this.form = {};
      this.visible = false;
    },
    openEdit (item) {
      this.mode = MODE.EDIT;
      this.visible = true;
      this.form = _.cloneDeep(item);
    },
    openAdd () {
      this.mode = MODE.ADD;
      this.visible = true;
      this.form = {
        state: true
      };
    },
    saveOrUpdate () {

      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }

        const data = this.form;
        if (this.mode === MODE.EDIT) {
          modifyAheadPriceRule(data).then(() => {
            this.$message.success("早价规则修改成功");
            this.visible = false;
            this.query();
          });
        } else {
          addAheadPriceRule(data).then(() => {
            this.$message.success("早价规则新增成功");
            this.visible = false;
            this.query();
          });
        }
      });

    },
    changeState (item) {
      const data = {
        id: item.id,
        state: item.state ? 0 : 1
      };
      modifyAheadPriceRule(data).then(() => {
        this.$message.success("状态修改成功");
        this.query();
      });
    },
    query () {
      listAheadPriceRule(Toolkit.filterEmpty(this.param)).then(res=>{
        this.list = res.data || [];
      });
    }

  }
};
</script>

<style scoped lang="less">
</style>
