<template>
  <div class="root-box">
    <div class="query-container space-between">
      <div>
        <el-form size="small" :inline="true">
          <el-form-item>
            <el-select
              style="width: 150px"
              v-model="param.invoiceType"
              placeholder="开票类型："
              @change="query()"
              clearable
            >
              <el-option
                v-for="item in receiptTypeOpts"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-select
              v-model="param.state"
              clearable
              @change="query()"
              style="width: 150px"
              placeholder="开票状态："
            >
              <el-option
                v-for="item in invoiceStateOpts"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-input v-model="param.companyName" placeholder="公司名称" clearable></el-input>
          </el-form-item>

          <el-form-item>
            <el-input v-model="param.passengerTel" placeholder="账户手机号" clearable></el-input>
          </el-form-item>

          <el-form-item>
            <el-button style="width: 100px; margin-right: 20px" type="primary" @click="query()"
              >查询</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div>
        <el-button type="primary" :size="size" @click="addReceipt">新增开票</el-button>

        <el-button type="primary" :size="size" @click="downloadExcel">下载excel</el-button>
      </div>
    </div>

    <el-dialog
      title="新增开票"
      :visible.sync="formVisible"
      width="600px"
      max-height="600"
      :size="size"
      :close-on-click-modal="false"
    >
      <el-form ref="form" :model="form" :size="size" label-width="120px" :rules="rules">
        <el-form-item label="账户：" prop="passengerTel" clearable>
          <el-input v-model="form.passengerTel"></el-input>
        </el-form-item>
        <el-form-item label="开票类型：" prop="invoiceType">
          <el-select v-model="form.invoiceType" style="width: 100%">
            <el-option
              v-for="item in receiptTypeOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="名称：" prop="companyName">
          <el-input v-model="form.companyName" placeholder="个人或者公司名称" clearable></el-input>
        </el-form-item>

        <el-form-item label="税号：" prop="taxNo">
          <el-input v-model="form.taxNo" placeholder="个人票无需填写税号" clearable></el-input>
        </el-form-item>

        <el-form-item label="乘车人姓名：" prop="name">
          <el-input v-model="form.name" clearable></el-input>
        </el-form-item>

        <el-form-item label="身份证号码：" prop="expressNo">
          <el-input v-model="form.expressNo" clearable></el-input>
        </el-form-item>

        <el-form-item label="邮箱：" prop="email">
          <el-input v-model="form.email" clearable></el-input>
        </el-form-item>
        <el-form-item label="开户行：" prop="bank">
          <el-input v-model="form.bank" clearable></el-input>
        </el-form-item>
        <el-form-item label="开户行账号：" prop="bankAccount">
          <el-input v-model="form.bankAccount" clearable></el-input>
        </el-form-item>
        <el-form-item label="单位地址：" prop="companyAddress">
          <el-input v-model="form.companyAddress" clearable></el-input>
        </el-form-item>
        <el-form-item label="单位电话：" prop="companyPhone">
          <el-input v-model="form.companyPhone" clearable></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button type="warning" :size="size" @click="saveOrUpdate">保 存</el-button>
        <el-button type="info" :size="size" @click="closeDialog">取 消</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="上传发票"
      :visible.sync="uploadVisible"
      width="600px"
      max-height="600"
      :size="size"
      :close-on-click-modal="false"
    >
      <el-form :model="form">
        <div style="margin: 10px 0">发送给：{{ form.companyName }} - {{ form.name }}</div>
        <el-form-item label="电子发票：">
          <!-- <file-upload ref="uploader" v-model="form.invoiceUrl" filePath="/passenger/invoice" /> -->

          <el-upload
            class="upload-demo"
            ref="uploadRef"
            action="/admin/invoice/upload/invoice"
            :on-success="handleSuccess"
            :on-error="handleError"
            :on-progress="handleProgress"
            :on-change="handleChange"
            :data="extraData"
            drag
            name="files"
            :auto-upload="false"
            multiple
          >
            <slot>
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            </slot>
          </el-upload>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button type="warning" :size="size" @click="onUpload">保 存</el-button>
        <el-button type="info" :size="size" @click="uploadVisible = false">取 消</el-button>
      </span>
    </el-dialog>

    <div class="table">
      <el-table
        :data="list"
        border
        stripe
        max-height="650"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="50"></el-table-column>

        <el-table-column
          type="index"
          label="序号"
          show-overflow-tooltip
          width="50"
        ></el-table-column>
        <el-table-column prop="state" label="上传状态" :width="80">
          <template slot-scope="scope">
            <span>{{ scope.row.state === 2 ? "上传成功" : "等待上传" }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="state" label="发票张数" :width="80">
          <template slot-scope="scope">
            <span>{{ scope.row.orderIdSet.length }}张</span>
          </template>
        </el-table-column>
        <el-table-column prop="passengerTel" label="账户" :width="120"></el-table-column>
        <el-table-column prop="invoiceType" label="开票类型" width="80">
          <template slot-scope="scope">
            <span>{{ scope.row.invoiceType | enumTransfer(receiptTypeOpts) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="invoiceFormat" label="开票格式" :width="90"></el-table-column>
        <el-table-column prop="companyName" label="公司名称" :width="180"></el-table-column>
        <el-table-column prop="taxNo" label="税号" :width="180"></el-table-column>

        <el-table-column prop="invoiceAmount" label="开票金额">
          <template slot-scope="scope">
            <span>{{ scope.row.invoiceAmount | moneyFormat }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="乘车人姓名" :width="120"></el-table-column>
        <el-table-column prop="expressNo" label="乘车人身份证号" :width="120"></el-table-column>

        <el-table-column prop="bank" label="开户行" width="130"></el-table-column>
        <el-table-column prop="bankAccount" label="开户行账号" :width="120"></el-table-column>
        <el-table-column prop="companyAddress" label="单位地址" width="200"></el-table-column>
        <el-table-column prop="companyPhone" label="单位电话"></el-table-column>
        <el-table-column prop="email" label="邮箱" width="160"></el-table-column>

        <el-table-column prop="createTime" label="申请时间" :width="160"></el-table-column>
        <el-table-column prop="msg" label="客服留言"></el-table-column>

        <!-- <el-table-column prop="remark" label="备注"></el-table-column> -->

        <!-- <el-table-column prop="tel" label="收件电话"></el-table-column>
        <el-table-column prop="address" label="收件地址"></el-table-column> -->
        <!-- <el-table-column prop="status" label="支付状态">
          <template slot-scope="scope">
            <span>{{ scope.row.status | enumTransfer(payStatusOpts) }}</span>
          </template>
        </el-table-column> -->

        <el-table-column label="操作" fixed="right" :width="260">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              :disabled="!scope.row.orderIds"
              @click="detailsQuery(scope.row.orderIds)"
              >查看详情</el-button
            >

            <el-button type="text" size="small" @click="msgSubmit(scope.row)">客服留言</el-button>
            <el-button type="text" size="small" @click="modifyInfo(scope.row)">修改信息</el-button>
            <el-button
              type="text"
              :style="{ color: scope.row.state === 2 ? '#606266' : '#409EFF' }"
              size="small"
              @click="upload(scope.row)"
              >{{ scope.row.state === 1 ? "上传发票" : "重新上传" }}</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <el-dialog title="订单详情" :visible.sync="travelTableVisible">
        <el-table :data="gridData">
          <el-table-column type="index" label="序号"></el-table-column>
          <el-table-column prop="path" label="路线">
            <template slot-scope="scope">
              <span>{{ scope.row.pathId | pathNameTransfer }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="date" label="日期"></el-table-column>
          <el-table-column prop="showTime" label="时间"></el-table-column>
          <el-table-column prop="number" label="人数"></el-table-column>
          <el-table-column prop="price" label="总价">
            <template slot-scope="scope">
              <span v-operate-order:bill="scope.row.id">{{ scope.row.price | moneyFormat }}元</span>
            </template>
          </el-table-column>

          <el-table-column prop="departure" label="起点"></el-table-column>
          <el-table-column prop="destination" label="终点"></el-table-column>
          <el-table-column prop="id" label="订单详情">
            <template slot-scope="scope">
              <span v-goRouteDetail="scope.row.id">详情</span>
            </template>
          </el-table-column>
        </el-table>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" :size="size" @click="travelTableVisible = false"
            >确 定</el-button
          >
        </div>
      </el-dialog>
    </div>

    <div class="page">
      <el-pagination
        @current-change="handleCurrentChange"
        :page-size="pageSize"
        :current-page="currentPage"
        background
        layout="total, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { listReceipt, listDetails, addReceipt, modifyReceipt } from "@/api";
import { mapState } from "vuex";
import { ParamUtil } from "@/util";
import { downloadFile, uploadFiles } from "@/util/http";
import _ from "lodash";
const telReg = /^1\d{10}$/;

export default {
  created() {
    this.query();
  },
  computed: {
    ...mapState({
      size: (state) => state.btnSize,
      payStatusOpts: (state) => state.enumMap["PAY_STATUS"],
      invoiceStateOpts: (state) => state.enumMap["INVOICE_STATE"],
    }),
  },
  data() {
    return {
      param: {},
      receiptTypeOpts: [
        { label: "对公", value: 1 },
        { label: "对私", value: 2 },
      ],
      receiptStatus: [
        { label: "已开", value: true },
        { label: "未开", value: false },
      ],
      form: {},
      list: [],
      uploadVisible: false,
      pageSize: 30,
      currentPage: 1,
      total: 0,
      travelTableVisible: false,
      gridData: [],
      rules: {
        passengerTel: [
          { required: true, message: "账户不能为空", trigger: "blur" },
          {
            pattern: telReg,
            message: "请输入正确的手机号码",
            trigger: "change",
          },
        ],
        invoiceType: [{ required: true, message: "开票类型不能为空", trigger: "blur" }],
        companyName: [{ required: true, message: "公司名称不能为空", trigger: "blur" }],
        remark: [{ required: true, message: "请备注路线", trigger: "blur" }],
        invoiceAmount: [{ required: true, message: "开票金额不能为空", trigger: "blur" }],
        name: [{ required: true, message: "收件信息不能为空", trigger: "blur" }],
        tel: [
          { required: true, message: "收件信息不能为空", trigger: "blur" },
          {
            pattern: telReg,
            message: "请输入正确的手机号码",
            trigger: "change",
          },
        ],
        address: [{ required: true, message: "收件信息不能为空", trigger: "blur" }],
      },
      formVisible: false,
      extraData: {},
    };
  },
  methods: {
    query(page = 1, param = this.param || {}) {
      const query = {
        page: {
          current: page,
          size: this.pageSize,
        },
        param: ParamUtil.filterEmptyData(param),
      };
      listReceipt(query).then((res) => {
        const { records = [], current = 1, total = 0 } = res.data || {};
        this.list = records;
        this.currentPage = current;
        this.total = total;
      });
    },
    detailsQuery(orderIds) {
      this.travelTableVisible = true;
      listDetails(orderIds).then((res) => {
        this.gridData = res.data || [];
      });
    },
    addReceipt() {
      this.formVisible = true;
      this.form = {};
    },

    saveOrUpdate() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }

        if (this.form.id) {
          modifyReceipt(this.form).then(() => {
            this.$message.success("操作成功");
            this.formVisible = false;
            this.query();
          });
        } else {
          addReceipt(this.form).then(() => {
            this.$message.success("操作成功");
            this.formVisible = false;
            this.query();
          });
        }
      });
    },

    closeDialog() {
      this.$refs.form.resetFields();
      this.formVisible = false;
    },

    msgSubmit(item) {
      this.$prompt("请输入留言内容", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
      })
        .then(({ value }) => {
          item.msg = value;
          modifyReceipt(item).then(() => {
            this.$message.success("留言成功");
            this.query();
          });
        })
        .catch(() => {});
    },

    upload(item) {
      if (this.$refs.uploadRef && this.$refs.uploadRef.clearFiles) {
        this.$refs.uploadRef.clearFiles();
      }
      this.form = item;
      this.uploadVisible = true;
    },

    onUpload() {
      const formData = new FormData();
      formData.append("id", this.form.id);
      this.files.forEach((file) => {
        formData.append("files", file.raw);
      });
      const url = "/admin/invoice/upload/invoice";
      uploadFiles(url, formData)
        .then(() => {
          this.$message.success("上传成功");
          this.uploadVisible = false;
          this.files = [];
          this.query();
        })
        .catch((error) => {
          console.error("上传出错", error);
        });
    },

    // eslint-disable-next-line no-unused-vars
    handleSuccess(response, file, fileList) {
      this.$message.success("上传成功");
      this.uploadVisible = false;
    },

    // eslint-disable-next-line no-unused-vars
    handleError(err, file, fileList) {
      console.eror(err);
      this.$message.error("发票上传失败");
    },

    // eslint-disable-next-line no-unused-vars
    handleProgress(event, file, fileList) {
      // 可以在这里处理上传进度的逻辑
      console.log("上传进度", event.percent);
    },

    handleChange(file, fileList) {
      this.files = fileList;
    },

    //每页显示的条数
    // handleSizeChange (val) {
    //     //改变每页显示的条数
    //     this.pageSize = val;

    // },
    // 显示第几页
    handleCurrentChange(val) {
      //改变默认的页数
      this.currentPage = val;
      this.query(val);
    },

    handleSelectionChange(val) {
      this.selectOrderIds = val.map((item) => item.id);
    },

    downloadExcel() {
      if (this.selectOrderIds && this.selectOrderIds.length) {
        downloadFile("/admin/invoice/download/excel", this.selectOrderIds, "sss.xlsx");
      } else {
        this.$message.error("请勾选要开的发票");
      }
    },

    modifyInfo(item) {
      this.form = _.cloneDeep(item);
      this.formVisible = true;
    },
  },
  //methods
};
</script>

<style scoped lang="less">
.table {
  margin: 20px 0;
}

.page {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}
</style>
