<template>
  <div class="container">
    <div class="tab">
      <div>
        {{ routeInfo.driverNo }} {{ routeInfo.pathId | pathNameTransfer }} {{ routeInfo.showTime }}
      </div>
      <el-radio-group v-model="isPick">
        <el-radio :label="true">接人排序</el-radio>
        <el-radio :label="false">送人排序</el-radio>
      </el-radio-group>
      <div></div>
    </div>

    <div class="box">
      <div id="planMap" class="map"></div>

      <sortable-list lockAxis="y" v-model="list" :useDragHandle="true">
        <OrderItem
          v-for="(item, index) in list"
          :index="index"
          :key="item.orderId"
          :item1="item"
          :data="data"
          :isPick="isPick"
          :disable="item.pointStatus === PointStatus.DONE"
          :active="item.orderId === selectId"
          :orderMap="orderMap"
          :onUpdate="onUpdate"
          :onClick="handleClick"
        />
      </sortable-list>

      <div class="order-info" v-if="routePlan">
        <div class="info">
          <el-divider content-position="left">行程概况</el-divider>

          <el-form size="small" inline>
            <el-form-item>
              全程{{ routePlan.totalDistance | mileFilter }}，路程时长{{
                routePlan.totalDuration | timeFilter
              }}，{{ isPick ? routePlan.pickDesc : routePlan.sendDesc }}
            </el-form-item>

            <el-row>
              <el-col :span="12">
                <el-form-item label="手动指派顺序：">
                  {{ routePlan.isManuallySpecify ? "是" : "否" }}
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="是否确认：">
                  {{ routePlan.isConfirmed ? "是" : "否" }}
                </el-form-item>
              </el-col>
            </el-row>

            <el-form-item label="首位时间：">
              <el-time-picker
                v-model="routePlan.startTime"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="选择到达首位时间"
              >
              </el-time-picker>
            </el-form-item>

            <el-form-item label="备注说明：">
              <el-input type="textarea" style="width: 300px" v-model="routePlan.remark" />
            </el-form-item>

            <el-form-item label="高速入口：">
              <el-select
                v-model="routePlan.expresswayEntranceId"
                filterable
                placeholder="请选择高速入口"
                @change="(val) => onExpresswayChange(true, val)"
                clearable
                style="width: 300px"
              >
                <el-option
                  v-for="item in highwayListMap.entrance"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>

            <el-form-item label="高速出口：">
              <el-select
                v-model="routePlan.expresswayExitId"
                clearable
                filterable
                placeholder="请选择高速出口"
                @change="(val) => onExpresswayChange(false, val)"
                style="width: 300px"
              >
                <el-option
                  v-for="item in highwayListMap.exit"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>

            <!-- <el-form-item label="高速路费：">
              <el-input-number style="width: 300px" v-model="routePlan.expresswayCost" />
            </el-form-item> -->
          </el-form>
          <div style="margin: 10px 0">
            <el-row>
              <el-button type="primary" size="small" @click="showShuntMap" plain
                >安排分流</el-button
              >

              <el-button type="primary" size="small" plain @click="refresh">刷新排序</el-button>

              <el-button type="primary" size="small" @click="comparePlan" plain>对比方案</el-button>

              <el-button type="primary" size="small" @click="testPlan" plain>测试方案</el-button>
            </el-row>

            <div style="height: 10px"></div>

            <el-row>
              <el-button type="primary" size="small" @click="sysPlan" plain>系统重新规划</el-button>

              <el-button type="warning" size="small" plain @click="savePlan">保存方案</el-button>
            </el-row>
          </div>
        </div>

        <div class="result setting right">
          <el-form>
            <el-form-item>
              <el-button
                type="primary"
                style="width: 48%"
                size="small"
                plain
                @click="addShunt(false)"
                >送人分流</el-button
              >

              <el-button
                type="primary"
                style="width: 48%"
                size="small"
                plain
                @click="addShunt(true)"
                >接人分流</el-button
              >
            </el-form-item>
            <el-table
              :data="shuntList"
              stripe
              size="small"
              :show-header="false"
              @row-click="showCurrent"
            >
              <el-table-column label="序号" type="index" width="20"></el-table-column>

              <el-table-column prop="isStartShunt" width="140">
                <template slot-scope="scope">{{ scope.row.name }}</template>
              </el-table-column>

              <el-table-column prop="id" label="操作" align="right">
                <template slot-scope="scope">
                  <span v-if="scope.row.prinum" style="margin-right: 10px">
                    <tel-record
                      :userTel="scope.row.passengerTel"
                      :prinum="scope.row.prinum"
                      :status="scope.row.prinumBindStatus"
                      :queryKey="`${scope.row.id}`"
                      :orderId="0"
                      :type="4"
                      :userType="1"
                      :isShuntStart="scope.row.isStartShunt"
                    >
                      <el-button type="text" size="small">通话</el-button>
                    </tel-record>
                  </span>

                  <el-button type="text" size="small" @click="editShunt(scope.row)">修改</el-button>
                  <el-button type="text" size="small" @click="delShunt(scope.row)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-form>
        </div>

        <div v-if="activeOrder" class="form">
          <el-divider content-position="left">订单信息</el-divider>

          <el-form inline>
            <el-row>
              <el-col :span="12">
                <el-form-item label="手机号：">
                  <tel-cell :order="activeOrder" />
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="下单时间：">
                  {{ activeOrder.createTime }}
                </el-form-item>
              </el-col>
            </el-row>

            <el-row
              v-if="(isPick && activeOrder.startShuntId) || (!isPick && activeOrder.endShuntId)"
            >
              <el-form-item label="这是一个分流订单，原地址：">
                {{ isPick ? activeOrder.departure : activeOrder.destination }}
              </el-form-item>
            </el-row>

            <el-row v-if="activeOrder.psgMessage">
              <el-form-item label="乘客留言：">
                {{ activeOrder.psgMessage }}
              </el-form-item>
            </el-row>

            <el-row v-if="activeOrder.baggageRemark">
              <el-form-item label="行李备注：">
                {{ activeOrder.baggageRemark }}
              </el-form-item>
            </el-row>

            <el-row v-if="activeOrder.remark">
              <el-form-item label="司机备注：">
                {{ activeOrder.remark }}
              </el-form-item>
            </el-row>

            <el-row v-if="activeOrder.adminMessage">
              <el-form-item label="客服备注：">
                {{ activeOrder.adminMessage }}
              </el-form-item>
            </el-row>
          </el-form>
        </div>

        <div v-if="compareArr.length" class="compare-box">
          <el-divider content-position="left">方案结果对比</el-divider>

          <div
            v-for="(item, index) in compareArr"
            class="compare-item"
            :class="{ active: selectPlanIndex === index }"
            @click="applyPlan(item, index)"
            :key="index"
          >
            <div class="title">
              <div>
                <span>方案{{ index + 1 }}：</span>

                <span v-for="(order, _index) in item.orders" :key="order.orderId">
                  <span v-if="_index > 0"> → </span>
                  <span class="circle" :class="{ done: order.pointStatus === PointStatus.DONE }">
                    {{ order.orderIndex + 1 }}
                  </span>
                </span>
              </div>

              <div>
                <el-button type="text" @click="delPlan(index)">删除</el-button>
              </div>
            </div>

            <div>
              {{ item.routeInfo.distance | mileFilter }}，{{ item.routeInfo.time | timeFilter }}
            </div>
          </div>
        </div>

        <div
          class="confirm-btn"
          @click="confirmVisible = true"
          :class="{ active: !routePlan.isConfirmed }"
        >
          确认当前方案
        </div>
      </div>
    </div>

    <el-dialog
      :visible.sync="confirmVisible"
      width="440px"
      :append-to-body="true"
      :show-close="false"
      custom-class="confirm-box"
      :close-on-click-modal="false"
    >
      <div class="tip">提示</div>

      <div class="content"><i class="el-icon-warning"></i> 确认当前规划吗？</div>

      <span slot="footer" class="footer">
        <el-button @click="confirmVisible = false" size="small">取消</el-button>
        <el-button @click="confirmPlan(true)" type="primary" v-if="needShow2MatchBtn" size="small"
          >确认并转正</el-button
        >
        <el-button @click="confirmPlan(false)" type="success" size="small">确认</el-button>
      </span>
    </el-dialog>

    <div v-drag v-if="showShuntForm" class="shunt-container">
      <div class="shunt-header"></div>
      <div @mousedown.stop class="shunt-form">
        <el-form size="small" inline :rules="rules" ref="shuntForm" :model="shuntForm">
          <div class="order-list">
            <el-form-item>
              <el-input
                :disabled="shuntForm.status === 3"
                v-model="shuntForm.name"
                placeholder="输入计划名称"
              ></el-input>
            </el-form-item>
            <el-button
              :disabled="shuntForm.status === 3"
              type="primary"
              icon="el-icon-circle-plus-outline"
              size="small"
              @click="addShuntPerson"
              >添加分流订单</el-button
            >
          </div>

          <div class="order-item-box">
            <div class="order-item" v-for="(orderId, index) in shuntForm.orderIds" :key="index">
              <el-form-item>
                <el-select
                  v-model="shuntForm.orderIds[index]"
                  size="small"
                  style="width: 280px"
                  :disabled="shuntForm.status === 3"
                >
                  <el-option
                    v-for="item in chooseOrders"
                    :key="item.id"
                    :label="`${item.orderIndex + 1}号单-${item.address}`"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>

              <el-button
                v-if="shuntForm.orderIds.length > 1"
                type="text"
                plain
                icon="el-icon-remove-outline"
                size="small"
                @click="minusShuntPerson(index)"
                :disabled="shuntForm.status === 3"
                >去除</el-button
              >

              <el-button type="text" plain size="small" @click="genUrlLink(true, orderId)"
                >链接</el-button
              >

              <el-button type="text" plain size="small" @click="sendMsg(orderId)"
                >发送短信</el-button
              >

              <span
                style="font-size: 12px; margin-left: 4px"
                v-if="shuntForm.sendMsgOrderIds && shuntForm.sendMsgOrderIds.includes(orderId)"
                >已发送</span
              >
            </div>
          </div>

          <el-form-item>
            <el-autocomplete
              style="width: 350px"
              class="inline-input"
              v-model="shuntForm.shuntAddress"
              value-key="name"
              :fetch-suggestions="querySearch"
              placeholder="请选择或输入分流点"
              :trigger-on-focus="true"
              @select="handleSelect"
              clearable
              :disabled="shuntForm.status === 3"
            ></el-autocomplete>

            <el-button
              icon="el-icon-map-location"
              @click="triggerShuntMarkers"
              style="width: 40px; margin-left: 8px"
            >
            </el-button>

            <el-button type="text" plain size="small" @click="genUrlLink(false)">链接</el-button>
          </el-form-item>

          <el-form-item prop="callTel">
            <el-select
              v-model="shuntForm.callTel"
              :disabled="shuntForm.status === 3"
              style="width: 240px"
              placeholder="代叫人手机号"
              filterable
              allow-create
              default-first-option
            >
              <el-option v-for="item in callTels" :key="item" :label="item" :value="item">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-select
              v-model="shuntForm.carPlatform"
              size="small"
              placeholder="分流平台"
              style="width: 150px"
              clearable
              @change="handlePlatformChange"
              :disabled="shuntForm.status === 3"
            >
              <el-option v-for="item in carPlatformOpts" :key="item" :label="item" :value="item">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-select
              v-model="shuntForm.vehicleType"
              size="small"
              placeholder="车型"
              style="width: 100px"
              clearable
              :disabled="shuntForm.status === 3"
            >
              <el-option v-for="item in vehicleTypeOpts" :key="item" :label="item" :value="item">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-select
              v-model="shuntForm.vehicleColor"
              size="small"
              placeholder="车辆颜色"
              style="width: 100px"
              filterable
              allow-create
              clearable
              :disabled="shuntForm.status === 3"
            >
              <el-option v-for="item in vehicleColorOpts" :key="item" :label="item" :value="item">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-input
              v-model="shuntForm.vehicleBrand"
              size="small"
              placeholder="车辆品牌"
              style="width: 180px"
              clearable
              :disabled="shuntForm.status === 3"
            >
            </el-input>
          </el-form-item>

          <el-form-item>
            <el-input
              v-model="shuntForm.vehicleNo"
              style="width: 130px"
              :disabled="shuntForm.status === 3"
            ></el-input>
          </el-form-item>

          <el-form-item>
            <el-input
              v-model="shuntForm.vehicleDriver"
              style="width: 80px"
              :disabled="shuntForm.status === 3"
            ></el-input>
            师傅
          </el-form-item>

          <el-form-item prop="driverTel">
            <el-input
              v-model="shuntForm.driverTel"
              placeholder="手机号"
              style="width: 140px"
              :disabled="shuntForm.status === 3"
            ></el-input>
          </el-form-item>

          <el-form-item>
            <el-input
              style="width: 120px"
              v-model="shuntForm.bookTime"
              placeholder="预约分流时间"
              :disabled="shuntForm.status === 3"
            >
            </el-input>
          </el-form-item>

          <el-form-item>
            <el-input-number
              style="width: 130px"
              v-model="shuntForm.prePrice"
              placeholder="预估价格"
              :disabled="shuntForm.status === 3"
            >
            </el-input-number>
          </el-form-item>

          <el-form-item>
            <el-input-number
              style="width: 140px"
              v-model="shuntForm.actPrice"
              placeholder="实际价格"
              :disabled="shuntForm.status === 3"
            >
            </el-input-number>
          </el-form-item>

          <el-form-item>
            <el-input v-model="shuntForm.remark" placeholder="备注" style="width: 400px"></el-input>
          </el-form-item>

          <div class="footer">
            <el-form-item>
              <el-button type="" @click="closeShuntForm">关闭</el-button>
              <el-button type="info" v-if="!shuntForm.status" @click="saveShunt(0)">暂存</el-button>
              <el-button
                v-if="shuntForm.status !== 3"
                :type="shuntForm.status === 1 ? 'warning' : 'primary'"
                @click="saveShunt(1)"
                >{{ shuntForm.status === 1 ? "修改分流" : "确认分流" }}</el-button
              >
              <el-button type="success" v-if="shuntForm.status === 1" @click="saveShunt(3)"
                >确认费用</el-button
              >
            </el-form-item>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import OrderItem from "./OrderItem.vue";
import { SHOW_SHUNT_MAP, ORDER_CHANGE } from "@/core/const/global-event";
import { mapState } from "vuex";
import _ from "lodash";
import { BaseDataService } from "@/service";

import {
  simulateRoutePlan,
  manualSpecPlan,
  getDriverLocation,
  confirmRoutePlan,
  updatePreDispatch2Match,
  createSysRoutePlan,
  getRouteShunt,
  listRecommendAddress,
  saveRouteShunt,
  getDriverInfo,
  delShuntOrder,
  sortShuntOrder,
  listHighways,
} from "@/api";
const carPng = require("../../assets/image/car_blue.png");
const markY = require("../../assets/image/mark_y.png");
const markR = require("../../assets/image/mark_r.png");
const sfz = require("../../assets/image/sfz.png");

const RouteStatus = {
  NONE: 0,
  GO_DEP: 1,
  ARRIVE_DEP: 2,
  GET_PERSON: 3,
  ARRIVE_DEST: 4,
  GO_DEST: 6,
  FINISH: 5,
};

const PointStatus = { TODO: 0, DOING: 1, DONE: 2 };

function getPointStatus(routeStatus, isStartShunt) {
  // 接人完成状态
  const startFinishStatus = [
    RouteStatus.GET_PERSON,
    RouteStatus.GO_DEST,
    RouteStatus.ARRIVE_DEST,
    RouteStatus.FINISH,
  ];
  // 送人完成状态
  const endFinishStatus = [RouteStatus.ARRIVE_DEST, RouteStatus.FINISH];
  if (isStartShunt) {
    if (startFinishStatus.includes(routeStatus)) {
      return PointStatus.DONE;
    } else if (routeStatus == RouteStatus.GO_DEP || routeStatus == RouteStatus.ARRIVE_DEP) {
      return PointStatus.DOING;
    } else {
      return PointStatus.TODO;
    }
  } else {
    if (endFinishStatus.includes(routeStatus)) {
      return PointStatus.DONE;
    } else if (routeStatus == RouteStatus.GO_DEST) {
      return PointStatus.DOING;
    } else {
      return PointStatus.TODO;
    }
  }
}

function transList(list, isPick) {
  return list.map((item) => {
    return {
      ...item,
      isPick,
      pointStatus: getPointStatus(item.routeStatus, isPick),
    };
  });
}

export default {
  components: {
    OrderItem,
  },

  props: {
    refresh: Function,
    closeFun: Function,
    data: Object,
    needShow2MatchBtn: Boolean,
  },

  watch: {
    isPick(val) {
      this.onIsPickChange(val);
    },

    latlng(val) {
      if (val) {
        this.sortOrders();
      } else {
        this.clearShunt();
      }
    },

    list() {
      this.drawRoute();
    },
  },

  computed: {
    ...mapState({
      pathMaps: (state) => {
        const pathList = state.pathList;
        return _.keyBy(pathList, "id");
      },
    }),

    orderMap() {
      return _.keyBy(this.data.orders, "id");
    },

    activeOrder() {
      return this.orderMap[this.selectId];
    },

    chooseOrders() {
      const items = this.data.orders.map((item) => {
        const add = this.isStartShunt ? item.departure : item.destination;
        return {
          id: item.id,
          address: add,
          orderIndex: item.orderIndex,
          lat: this.isStartShunt ? item.depLatitude : item.destLatitude,
          lng: this.isStartShunt ? item.depLongitude : item.destLongitude,
        };
      });
      return items;
    },

    latlng() {
      if (this.shuntForm) {
        const { shuntLat, shuntLng, orderIds = [] } = this.shuntForm;
        const ids = [...orderIds].sort().toString();
        return `${shuntLat}-${shuntLng}-${ids}`;
      }
      return null;
    },
  },

  mounted() {
    this.initMap();
    this.initData();
    this.queryShuntList();
  },

  beforeDestroy() {
    // 销毁地图
    this.driving = null;
    this.map.destroy && this.map.destroy();
  },

  data() {
    return {
      confirmVisible: false,
      list: [],
      selectId: null,
      routePlan: null,
      compareArr: [],
      selectPlanIndex: null,
      PointStatus,
      isPick: true,
      shuntList: [],
      shuntForm: {},
      isStartShunt: true,
      showShuntForm: false,
      rules: {
        callTel: [{ pattern: /^1\d{10}$/, message: "请输入正确的手机号" }],
        driverTel: [{ pattern: /^1\d{10}$/, message: "请输入正确的手机号" }],
      },
      vehicleTypeOpts: ["快车", "专车"],
      carPlatformOpts: ["滴滴", "高德", "城小班", "出租车"],
      vehicleColorOpts: BaseDataService.vehicleColorOpts(),
      shuntPointList: [],
      callTels: ["18674098185", "13456768768", "13871291670", "15334167000", "15107176274"],
      shuntMarkers: [],
      showShuntPoints: false,
      highwayListMap: {},
      routeInfo: {},
    };
  },

  methods: {
    async initData() {
      this.compareArr = [];
      this.selectId = null;
      this.selectPlanIndex = null;
      const data = this.data;
      // 转换数据
      data.routePlan.pickOrderList = transList(data.routePlan.pickOrderList, true);
      data.routePlan.sendOrderList = transList(data.routePlan.sendOrderList, false);
      this.routePlan = { ...data.routePlan };
      this.routeInfo = {
        showTime: data.showTime,
        pathId: data.pathId,
        driverNo: data.driverNo,
      };
      const { pickOrderList, sendOrderList } = data.routePlan;
      this.list = this.isPick ? [...pickOrderList] : [...sendOrderList];
      // 查询高速路口
      const res = await listHighways(data.pathId);
      this.highwayListMap = res.data;
      this.showDriverLocation();
      this.renderMap();
    },

    initMap() {
      const map = new AMap.Map("planMap", {
        resizeEnable: true,
        dragEnable: true,
        zoom: 14,
      });

      this.map = map;

      AMap.plugin("AMap.Driving", () => {
        const driving = new AMap.Driving({
          // 驾车路线规划策略，考虑实时路况
          policy: AMap.DrivingPolicy.REAL_TRAFFIC,
          autoFitView: false,
          hideMarkers: true,
          map,
        });

        this.driving = driving;
      });

      const autoOptions = {
        city: "027" || "全国", // city 限定城市，默认全国
        citylimit: false,
      };
      AMap.plugin(["AMap.AutoComplete", "AMap.Geocoder", "AMap.Driving"], () => {
        this.autoComplete = new AMap.AutoComplete(autoOptions);
        this.geocoder = new window.AMap.Geocoder();
      });
      this.map = map;
    },

    onIsPickChange(isPick) {
      const { pickOrderList, sendOrderList } = this.routePlan;
      this.list = isPick ? [...pickOrderList] : [...sendOrderList];
      this.renderMap();
    },

    renderMap() {
      const map = this.map;
      map.clearMap();
      // 视窗内的marker
      const pickViewMarkers = [];
      const sendViewMarkers = [];
      let selectedOrder = null;
      const { pickOrderList, sendOrderList } = this.routePlan;
      const allOrders = [...pickOrderList, ...sendOrderList];

      allOrders.forEach((item) => {
        const selected = item.orderId === this.selectId;
        const box = item.isPick ? pickViewMarkers : sendViewMarkers;
        if (selected) {
          if (this.isPick && item.isPick) {
            selectedOrder = item;
          } else if (!this.isPick && !item.isPick) {
            selectedOrder = item;
          }
        } else {
          const marker = this.getMarker(item, false, false);
          box.push(marker);
          if (item.isShunt) {
            const shuntMarker = this.getMarker(item, false, true);
            box.push(shuntMarker);
          }
        }
      });

      if (selectedOrder) {
        const box = this.isPick ? pickViewMarkers : sendViewMarkers;
        const marker = this.getMarker(selectedOrder, true, false);
        box.push(marker);
        if (selectedOrder.isShunt) {
          const shuntMarker = this.getMarker(selectedOrder, true, true);
          box.push(shuntMarker);
        }
      }

      // 所有要展示的marker
      const showMarkers = [...pickViewMarkers, ...sendViewMarkers];

      // 将指定的高速出入口展示在视窗内
      const {
        expresswayExitLat,
        expresswayExitLng,
        expresswayExitName,
        expresswayEntranceName,
        expresswayEntranceLng,
        expresswayEntranceLat,
        expresswayEntranceId,
        expresswayExitId,
      } = this.routePlan;
      if (expresswayEntranceId) {
        const entranceMarker = new AMap.Marker({
          icon: new AMap.Icon({
            image: sfz,
          }),
          position: [expresswayEntranceLng, expresswayEntranceLat],
          label: {
            content: "<div class='info'>" + expresswayEntranceName + "</div>",
            direction: "top",
          },
          anchor: "bottom-center",
        });
        showMarkers.push(entranceMarker);
        if (this.isPick) {
          pickViewMarkers.push(entranceMarker);
        }
      }

      if (expresswayExitId) {
        const exitMarker = new AMap.Marker({
          icon: new AMap.Icon({
            image: sfz,
          }),
          position: [expresswayExitLng, expresswayExitLat],
          label: {
            content: "<div class='info'>" + expresswayExitName + "</div>",
            direction: "top",
          },
          anchor: "bottom-center",
        });
        showMarkers.push(exitMarker);
        if (!this.isPick) {
          sendViewMarkers.push(exitMarker);
        }
      }

      map.add(showMarkers);

      if (this.selectCar) {
        map.add(this.selectCar);
      }

      this.pickViewMarkers = pickViewMarkers;
      this.sendViewMarkers = sendViewMarkers;
      this.showAllHighway();
      this.mapSetFitView();
    },

    showAllHighway() {
      const map = this.map;
      const { expresswayEntranceId, expresswayExitId } = this.routePlan;
      // 没选择高速出入口时，展示高速出入口
      if (!expresswayEntranceId && this.highwayListMap.entrance) {
        const highWays = this.highwayListMap.entrance;
        const highwayMarker = [];
        for (let i = 0; i < highWays.length; i++) {
          const item = highWays[i];
          const hMarker = new AMap.Marker({
            icon: new AMap.Icon({
              image: sfz,
            }),
            // label : {
            //     content : "<div class='info'>" + `${item.name}` + "</div>",
            //     direction : "top",
            // },
            title: item.name,
            anchor: "bottom-center",
            position: [item.longitude, item.latitude],
            clickable: true,
            draggable: true,
            raiseOnDrag: false,
          });
          highwayMarker.push(hMarker);
        }
        map.add(highwayMarker);
      }

      if (!expresswayExitId && this.highwayListMap.exit) {
        const highWays = this.highwayListMap.exit;
        const highwayMarker = [];
        for (let i = 0; i < highWays.length; i++) {
          const item = highWays[i];
          const hMarker = new AMap.Marker({
            icon: new AMap.Icon({
              image: sfz,
            }),
            title: item.name,
            anchor: "bottom-center",
            position: [item.longitude, item.latitude],
            clickable: true,
            draggable: true,
            raiseOnDrag: false,
          });
          highwayMarker.push(hMarker);
        }
        map.add(highwayMarker);
      }
    },

    getMarker(order, selected, isShunt) {
      const { lat, lng, oriLat, oriLng, orderIndex, pointStatus } = order || {};

      let type = "";
      if (pointStatus == PointStatus.DOING) {
        type = "green";
      } else if (pointStatus == PointStatus.TODO) {
        type = "red";
      } else if (pointStatus == PointStatus.DONE) {
        type = "grey";
      }
      if (selected) {
        type = `${type}_st`;
      }
      let indexStr = "f";
      if (isShunt) {
        indexStr = "f";
      } else if (order.isThing) {
        indexStr = "h";
      } else {
        indexStr = `${orderIndex + 1}`;
      }
      const iconImg = `/yxmgt/static/image/point/${type}_${indexStr}.png`;

      const position = isShunt ? [lng, lat] : [oriLng, oriLat];
      const marker = new AMap.Marker({
        icon: new AMap.Icon({
          image: iconImg,
          imageSize: selected ? new AMap.Size(70, 56) : new AMap.Size(60, 48),
        }),
        anchor: "bottom-center",
        position,
        clickable: true,
        draggable: true,
        raiseOnDrag: false,
        extData: order,
      });

      return marker;
    },

    showDriverLocation() {
      const map = this.map;
      const driverId = this.data.driverId;
      if (!driverId) {
        return;
      }
      getDriverLocation(driverId)
        .then((res) => {
          const { driver, location = {} } = res.data || {};

          if (location) {
            const { lat, lng, gpsTime, vendor } = location || {};
            const time = gpsTime.substr(5);
            const locationSource = vendor === "jimi" ? "GPS" : "手机";
            if (lat && lng) {
              const position = [lng, lat];
              const carMarker = new AMap.Marker({
                position,
                draggable: true,
                raiseOnDrag: false,
                anchor: "bottom-center",
                label: {
                  //修改label相对于maker的位置
                  direction: "top",
                  content:
                    "<div class='info'>" + `${driver.no} | ${time} | ${locationSource}` + "</div>",
                },
                icon: new AMap.Icon({
                  size: new AMap.Size(40, 40), //图标大小
                  image: carPng,
                  imageSize: new AMap.Size(40, 40),
                }),
              });
              map.add(carMarker);
              this.selectCar = carMarker;
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },

    drawRoute() {
      // 重置路程信息
      if (this.driving) {
        this.driving.clear();
      }

      const list = this.list.filter(
        (t) => getPointStatus(t.routeStatus, this.isPick) !== PointStatus.DONE
      );

      // 如果行程还没有开始，将高速出入口加入路线
      const {
        expresswayExitLat,
        expresswayExitLng,
        expresswayExitName,
        expresswayEntranceName,
        expresswayEntranceLng,
        expresswayEntranceLat,
        expresswayEntranceId,
        expresswayExitId,
      } = this.routePlan;
      const { startPick } = this.data;

      if (!startPick) {
        if (this.isPick && expresswayEntranceId) {
          list.push({
            lat: expresswayEntranceLat,
            lng: expresswayEntranceLng,
            name: expresswayEntranceName,
          });
        } else if (!this.isPick && expresswayExitId) {
          list.unshift({
            lat: expresswayExitLat,
            lng: expresswayExitLng,
            name: expresswayExitName,
          });
        }
      }

      const pointList = list.map((item) => [item.lng, item.lat]);
      if (this.selectCar && this.data.startPick) {
        pointList.unshift(this.selectCar.getPosition());
        this.mapSetFitView();
      }

      let waypoints = [];

      const total = pointList.length;
      if (total > 2) {
        waypoints = pointList.slice(1, -1);
      } else if (total < 2) {
        return;
      }

      const depLocation = pointList[0];
      const destLocation = pointList[total - 1];

      this.driving.search(depLocation, destLocation, { waypoints }, (status, result) => {
        if (status === "complete") {
          this.routeInfo = result.routes[0];
        }
      });
    },

    onExpresswayChange(isEntrance, id) {
      if (isEntrance) {
        if (id) {
          const entrance = this.highwayListMap.entrance.filter((t) => t.id === id)[0];
          const { latitude, longitude } = entrance;
          this.$set(this.routePlan, "expresswayEntranceLat", latitude);
          this.$set(this.routePlan, "expresswayEntranceLng", longitude);
        } else {
          this.$set(this.routePlan, "expresswayEntranceLat", null);
          this.$set(this.routePlan, "expresswayEntranceLng", null);
        }
      } else {
        if (id) {
          const exit = this.highwayListMap.exit.filter((t) => t.id === id)[0];
          const { latitude, longitude } = exit;
          this.$set(this.routePlan, "expresswayExitLat", latitude);
          this.$set(this.routePlan, "expresswayExitLng", longitude);
        } else {
          this.$set(this.routePlan, "expresswayExitLat", null);
          this.$set(this.routePlan, "expresswayExitLng", null);
        }
      }

      this.renderMap();
      this.drawRoute();
    },

    comparePlan() {
      const list = [];
      //已经完成的订单
      const doneList = [];

      this.list.forEach((item) => {
        const pointStatus = getPointStatus(item.routeStatus, this.isPick);
        if (pointStatus !== PointStatus.DONE) {
          list.push(item);
        } else {
          doneList.push(item);
        }
      });

      const pointList = list.map((item) => [item.lng, item.lat]);
      let total = pointList.length;
      if (total < 2) {
        this.$message.warning("只有一个订单无法对比");
        return;
      }

      // 检查是否已经包含此种方案
      const idList = list.map((item) => item.orderId);
      const hasOne = this.compareArr.filter((item) => {
        const list = item.orders.map((t) => t.orderId);
        return list.join() === idList.join();
      });

      if (hasOne.length) {
        this.$message.warning("已经存在此方案");
        return;
      }

      if (this.selectCar && this.data.startPick) {
        pointList.unshift(this.selectCar.getPosition());
        total += 1;
      }

      let waypoints = [];

      if (total > 2) {
        waypoints = pointList.slice(1, -1);
      }

      const depLocation = pointList[0];
      const destLocation = pointList[total - 1];

      this.driving.search(depLocation, destLocation, { waypoints }, (status, result) => {
        if (status === "complete") {
          const orders = [...list];
          const plan = {
            orders: [...doneList, ...orders],
            routeInfo: result.routes[0],
          };
          this.compareArr.push(plan);
          this.selectPlanIndex = this.compareArr.length - 1;
        }
      });
    },

    handleClick(item) {
      if (this.selectId === item.orderId) {
        this.selectId = null;
      } else {
        this.selectId = item.orderId;
      }
      this.renderMap();
    },

    testPlan() {
      const param = this.getParam();
      simulateRoutePlan(param).then((res) => {
        this.setData(res.data);
      });
    },

    sysPlan() {
      const { pathId } = this.data;
      const { routeId } = this.routePlan;
      const param = { pathId, routeId };
      createSysRoutePlan(param).then((res) => {
        this.setData(res.data);
      });
    },

    savePlan() {
      const param = this.getParam();
      manualSpecPlan(param).then((res) => {
        this.setData(res.data);
      });
    },

    setData(routePlan) {
      routePlan.pickOrderList = transList(routePlan.pickOrderList, true);
      routePlan.sendOrderList = transList(routePlan.sendOrderList, false);
      this.routePlan = routePlan;
      this.list = this.isPick
        ? transList(routePlan.pickOrderList, true)
        : transList(routePlan.sendOrderList, false);
    },

    getParam() {
      const seq = [];
      const waitTimeMap = {};

      this.list.forEach((item) => {
        seq.push(item.orderId);
        waitTimeMap[item.orderId] = item.waitTime;
      });

      const { pathId } = this.data;
      const {
        routeId,
        startTime,
        remark,
        pickOrderList,
        sendOrderList,
        expresswayEntranceId,
        expresswayExitId,
        expresswayCost,
      } = this.routePlan;

      // 送人的等待时间为0，需要从接人的列表中取
      if (!this.isPick) {
        pickOrderList.forEach((item) => {
          waitTimeMap[item.orderId] = item.waitTime;
        });
      }

      const oriPickSeq = pickOrderList.map((t) => t.orderId);
      const oriSendSeq = sendOrderList.map((t) => t.orderId);
      const param = {
        routeId,
        pathId,
        waitTimeMap,
        startTime,
        remark,
        pickSeq: this.isPick ? seq : oriPickSeq,
        sendSeq: this.isPick ? oriSendSeq : seq,
        expresswayCost,
      };

      if (expresswayEntranceId) {
        const entrance = this.highwayListMap.entrance.filter(
          (item) => item.id === expresswayEntranceId
        )[0];
        param.expresswayEntranceLat = entrance.latitude;
        param.expresswayEntranceLng = entrance.longitude;
        param.expresswayEntranceName = entrance.name;
        param.expresswayEntranceId = entrance.id;
      } else {
        param.expresswayEntranceLat = null;
        param.expresswayEntranceLng = null;
        param.expresswayEntranceName = null;
        param.expresswayExitLat = null;
        param.expresswayExitLng = null;
        param.expresswayExitName = null;
        param.expresswayCost = null;
        param.expresswayEntranceId = null;
      }

      if (expresswayExitId) {
        const exit = this.highwayListMap.exit.filter((item) => item.id === expresswayExitId)[0];
        param.expresswayExitLat = exit.latitude;
        param.expresswayExitLng = exit.longitude;
        param.expresswayExitName = exit.name;
        param.expresswayExitId = exit.id;
      } else {
        param.expresswayEntranceLat = null;
        param.expresswayEntranceLng = null;
        param.expresswayEntranceName = null;
        param.expresswayExitLat = null;
        param.expresswayExitLng = null;
        param.expresswayExitName = null;
        param.expresswayCost = null;
        param.expresswayExitId = null;
      }

      return param;
    },

    applyPlan(item, selectIndex) {
      this.list = item.orders;
      this.selectPlanIndex = selectIndex;
    },

    delPlan(index) {
      this.compareArr.splice(index, 1);
    },

    onUpdate(data, index) {
      this.$set(this.list, index, data);
    },

    mapSetFitView() {
      if (this.map) {
        const viewMarkers = this.isPick ? [...this.pickViewMarkers] : [...this.sendViewMarkers];
        if (this.selectCar && this.data.startPick) {
          viewMarkers.push(this.selectCar);
          this.map.setFitView(viewMarkers);
        } else {
          this.map.setFitView(viewMarkers);
        }
      }
    },

    confirmPlan(need2matched) {
      const { routeId } = this.routePlan;
      const { pathId } = this.data;
      confirmRoutePlan(routeId).then(() => {
        this.$message.success("确认成功");
        this.$set(this.routePlan, "isConfirmed", true);
        if (need2matched) {
          const param = {
            routeId,
            pathId,
          };
          updatePreDispatch2Match(param).then(() => {
            this.confirmVisible = false;
            this.closeFun();
            this.$bus.$emit(ORDER_CHANGE);
          });
        } else {
          this.confirmVisible = false;
          this.closeFun();
          this.$bus.$emit(ORDER_CHANGE);
        }
      });
    },

    //-----------------------------------  分流相关方法 start ------------------------------

    queryShuntList() {
      const data = this.data;
      getRouteShunt(data.id).then((res) => {
        this.shuntList = res.data || [];
      });
    },

    showCurrent(item) {
      this.shuntForm = _.cloneDeep(item);
    },

    editShunt(item) {
      this.shuntForm = _.cloneDeep(item);
      this.isStartShunt = this.shuntForm.isStartShunt;
      this.isPick = this.isStartShunt;
      this.queryShuntPoints();
      this.showShuntForm = true;
      this.createShuntMarker([item.shuntLng, item.shuntLat]);
    },

    queryShuntPoints() {
      const { pathId } = this.data || {};
      const routePath = this.pathMaps[pathId];
      const param = {
        areaId: this.isStartShunt ? routePath.startArea : routePath.endArea,
        type: 5,
        _hideLoading: true,
      };
      listRecommendAddress(param).then((res) => {
        this.shuntPointList = (res.data || []).map((item) => {
          return {
            lat: item.latitude,
            lng: item.longitude,
            name: item.name,
          };
        });
      });
    },

    showShuntMap() {
      this.$bus.$emit(SHOW_SHUNT_MAP, this.data, this.data.orders);
    },

    addShunt(isStartShunt) {
      this.isStartShunt = isStartShunt;
      this.isPick = isStartShunt;
      const { id } = this.data || {};
      this.queryShuntPoints();
      this.shuntForm = {
        orderIds: [""],
        name: this.isStartShunt ? "接人分流" : "送人分流",
        passengerTel: "",
        vehicleNo: "鄂A",
        isStartShunt,
        routeId: id,
      };
      this.showShuntForm = true;
    },

    addShuntPerson() {
      if (this.shuntForm.isStartShunt) {
        this.$message.warning("接人分流请确保多人同一地点上车");
      }
      if (this.shuntForm.orderIds) {
        if (this.shuntForm.orderIds.length < this.data.orders.length) {
          this.shuntForm.orderIds.push("");
        }

        console.table(this.shuntForm.orderIds);
      } else {
        this.$set(this.shuntForm, "orderIds", [""]);
      }
    },

    minusShuntPerson(index) {
      this.shuntForm.orderIds.splice(index, 1);
    },

    saveShunt(status) {
      const param = { ...this.shuntForm, status };
      if (status == 1) {
        this.$refs.shuntForm.validate((valid) => {
          if (valid) {
            saveRouteShunt(param).then(() => {
              this.closeShuntForm();
              this.queryShuntList();
              this.refresh();
              this.$message.success("操作成功");
            });
          }
        });
      } else {
        saveRouteShunt(param).then(() => {
          this.closeShuntForm();
          this.queryShuntList();
          this.refresh();

          this.$message.success("操作成功");
        });
      }
    },

    triggerShuntMarkers() {
      this.map.remove(this.shuntMarkers);
      this.showShuntPoints = !this.showShuntPoints;
      if (!this.showShuntPoints) {
        this.shuntMarkers = [];
        return;
      }
      const { pathId } = this.data;
      const routePath = this.pathMaps[pathId];
      const param = {
        areaId: this.isStartShunt ? routePath.startArea : routePath.endArea,
        type: 5,
        _hideLoading: true,
      };

      listRecommendAddress(param).then((res) => {
        const list = res.data || [];
        list.forEach((item) => {
          const lat = +item.latitude;
          const lng = +item.longitude;
          const marker = new AMap.Marker({
            position: [lng, lat],
            draggable: false,
            raiseOnDrag: false,
            anchor: "bottom-center",
            label: {
              content: "<div class='info'>" + item.name + "</div>",
              direction: "top",
            },
            icon: new AMap.Icon({
              image: markY,
            }),
            extData: item,
          });
          // 分流点拖动反向查询地址
          marker.on("dblclick", () => {
            const { name, latitude, longitude } = marker.getExtData();
            this.$set(this.shuntForm, "shuntLat", +latitude);
            this.$set(this.shuntForm, "shuntLng", +longitude);
            this.$set(this.shuntForm, "shuntAddress", name);
            this.createShuntMarker([+longitude, +latitude]);
            this.map.remove(this.shuntMarkers);
            this.showShuntPoints = false;
            this.shuntMarkers = [];
          });

          this.shuntMarkers.push(marker);
        });

        this.map.add(this.shuntMarkers);
      });
    },

    querySearch(key, cb) {
      if (!key) {
        cb(this.shuntPointList);
      } else {
        this.autoComplete.search(key, (status, result) => {
          if (status === "complete") {
            let res = result.tips || [];
            cb(this.transMapData(res));
          }
        });
      }
    },

    handleSelect(item) {
      this.$set(this.shuntForm, "shuntLat", item.lat);
      this.$set(this.shuntForm, "shuntLng", item.lng);
      this.createShuntMarker([item.lng, item.lat]);
    },

    createShuntMarker(latlng) {
      if (this.shuntMarker) {
        this.map.remove(this.shuntMarker);
      }
      this.shuntMarker = new AMap.Marker({
        position: latlng,
        draggable: true,
        raiseOnDrag: false,
        anchor: "bottom-center",
        label: {
          content: "<div class='info'>分流点</div>",
          direction: "top",
        },
        icon: new AMap.Icon({
          image: markR,
        }),
      });

      // 分流点拖动反向查询地址
      this.shuntMarker.on("dragend", (e) => {
        const { lat, lng } = e.lnglat;
        this.$set(this.shuntForm, "shuntLat", lat);
        this.$set(this.shuntForm, "shuntLng", lng);
        this.geocoder.getAddress([lng, lat], (status, result) => {
          if (status === "complete" && result.regeocode) {
            const address = result.regeocode.formattedAddress;
            this.$set(this.shuntForm, "shuntAddress", address);
          } else {
            console.error("根据经纬度查询地址失败");
          }
        });
      });

      this.shuntMarker.on("rightclick", () => {
        setTimeout(() => {
          this.$confirm("确认更新分流点吗？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            const status = this.shuntForm.status ? this.shuntForm.status : 0;
            this.saveShunt(status);
          });
        }, 0);
      });
      this.map.add(this.shuntMarker);
    },

    handlePlatformChange(item) {
      if (item === "自分流") {
        this.$prompt("请输入分流司机编号", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        }).then(({ value }) => {
          getDriverInfo({
            no: value,
            status: 1,
            _hideLoading: true,
          }).then((res) => {
            if (!res.data) {
              this.$message.error("不存在的司机");
              return;
            }
            const info = res.data;
            this.$set(this.shuntForm, "vehicleDriver", info.name.substr(0, 1));
            this.$set(this.shuntForm, "vehicleColor", info.vehicleColor);
            this.$set(this.shuntForm, "vehicleBrand", info.brand);
            this.$set(this.shuntForm, "driverTel", info.telephone);
            this.$set(this.shuntForm, "vehicleNo", info.vehicleNo);
          });
        });
      }
    },

    transMapData(data = []) {
      const list = [];
      data.forEach((item) => {
        if (item.location && item.location.lng) {
          list.push({
            name: `${item.district}${item.name}`,
            lng: `${item.location.lng}`,
            lat: `${item.location.lat}`,
          });
        }
      });
      return list;
    },

    closeShuntForm() {
      this.showShuntForm = false;
      if (this.shuntMarker) {
        this.map.remove(this.shuntMarker);
      }
      this.map.remove(this.shuntMarkers);
    },

    delShunt(item) {
      this.$confirm("确认删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delShuntOrder(item).then(() => {
          this.shuntForm = null;
          this.$message.success("操作成功");
          this.queryShuntList();
          this.refresh();
        });
      });
    },

    sortOrders() {
      const { id, pathId } = this.data || {};

      const paramOrderIds = this.shuntForm.orderIds || [];
      // 是否新新增分流点（有空字符串表示有新增），如果是新增，则不用计算排序
      const isAdd = paramOrderIds.find((item) => !item);
      if (isAdd !== undefined || !this.shuntForm.shuntLat) {
        return;
      }
      // 分流订单排序
      const param = {
        ...this.shuntForm,
        pathId,
        routeId: id,
      };
      sortShuntOrder(param).then((res) => {
        const { orderIds } = res.data || {};
        if (orderIds && orderIds.length) {
          this.$set(this.shuntForm, "orderIds", orderIds);
          this.renderPlan(res.data);
        }
      });
    },

    renderPlan(data) {
      if (!(data.orderIds && data.orderIds.length && data.shuntLng && data.shuntLat)) {
        return;
      }

      this.driving && this.driving.clear();
      this.isShuntStart = data.isStartShunt;
      const shuntPoint = [data.shuntLng, data.shuntLat];

      // 绘制规划路线
      const { orderIds = [] } = data;
      if (orderIds.length < 1) {
        return;
      }

      let destLocation,
        depLocation,
        waypoints = [];
      if (this.isShuntStart) {
        destLocation = shuntPoint;
      } else {
        depLocation = shuntPoint;
      }

      for (let index = 0; index < orderIds.length; index++) {
        const id = orderIds[index];
        const order = this.data.orders.find((t) => t.id === id);
        const lat = data.isStartShunt ? order.depLatitude : order.destLatitude;
        const lng = data.isStartShunt ? order.depLongitude : order.destLongitude;
        // 接人起点是同一个
        if (this.isShuntStart) {
          depLocation = [lng, lat];
        } else {
          if (index === orderIds.length - 1) {
            destLocation = [lng, lat];
          } else {
            waypoints.push([lng, lat]);
          }
        }
      }

      this.driving.search(
        depLocation,
        destLocation,
        { waypoints, map: this.map },
        (status, result) => {
          if (status === "complete") {
            this.routeInfo = result.routes[0];
          }
        }
      );
    },

    clearShunt() {
      // if (this.driving) {
      //     this.driving.clear();
      // }
      if (this.shuntMarker) {
        this.map.remove(this.shuntMarker);
      }
    },

    //-----------------------------------  分流相关方法 end ------------------------------
  },
};
</script>
<style scoped lang="less">
.container {
  display: flex;
  flex-direction: column;

  .tab {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
  }

  .box {
    display: flex;
    flex: 1;

    .map {
      width: 800px;
      min-height: 800px;
      max-height: 900px;
      margin-right: 10px;
    }

    .order-info {
      width: 500px;
      border: 1px solid #eee;
      margin-left: 10px;
      position: relative;
      padding-bottom: 40px;

      .el-form-item {
        margin-bottom: 6px;
      }

      .confirm-btn {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 30px;
        line-height: 30px;
        text-align: center;
        margin: 0;
        border: 1px solid #eee;
        cursor: pointer;

        &.active {
          background-color: orange;
          color: #fff;
        }
      }

      > div {
        background-color: #f4f4f4;
        padding: 4px 8px;
        margin-bottom: 10px;
      }

      .info {
        .just-between {
          display: flex;
          justify-content: space-between;
          margin-top: 8px;
        }
      }

      .form {
        .el-form-item {
          margin-bottom: 0;
        }
      }

      .compare-box {
        flex: 1;
        max-height: 400px;
        overflow: auto;

        .compare-item {
          border-bottom: 1px solid #eee;
          margin-bottom: 4px;
          padding: 4px 8px;

          &.active {
            background-color: #e8f5f5;
          }

          .title {
            display: flex;
            justify-content: space-between;
            margin-bottom: 4px;
            align-items: center;
          }

          .circle {
            width: 22px;
            height: 22px;
            border-radius: 100%;
            line-height: 22px;
            text-align: center;
            background-color: orange;
            color: #fff;
            margin: 0 2px;
            display: inline-block;

            &.done {
              background-color: #999;
            }
          }
        }
      }
    }
  }
}

/deep/.confirm-box {
  .el-dialog__body {
    padding: 0 20px 10px;
  }

  .tip {
    font-size: 18px;
    margin-bottom: 20px;
  }

  .content {
    display: flex;
    align-items: center;
    font-size: 14px;

    i {
      margin-right: 10px;
      font-size: 26px;
      color: orange;
    }
  }
}

.shunt-container {
  position: absolute;
  width: 500px;
  right: 600px;
  bottom: 20px;
  z-index: 9999;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0 0 8px #838080;
  max-height: 690px;
  user-select: none;

  .shunt-header {
    background: rgb(0, 122, 255);
    height: 50px;
    border-radius: 5px 5px 0 0;
  }

  .shunt-form {
    padding: 20px 20px 0;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 640px;
    box-sizing: border-box;
    user-select: none;

    .order-item-box {
      border-bottom: 1px solid #ddd;
      border-top: 1px solid #ddd;
      padding: 10px 0 0;
      margin-bottom: 20px;
    }

    .footer {
      margin-top: 30px;
      height: 50px;
      display: flex;
      justify-content: flex-end;
    }
  }
}
</style>
