<template>
  <div class="provider-root">
    <div class="query-container">
      <el-date-picker
        v-model="startDate"
        value-format="yyyy-MM-dd"
        type="date"
        placeholder="选择起始日期"
      >
      </el-date-picker>
      至
      <el-date-picker
        v-model="endDate"
        type="date"
        value-format="yyyy-MM-dd"
        placeholder="选择截至日期"
      >
      </el-date-picker>
      <el-select v-model="status" clearable placeholder="请选择">
        <el-option
          v-for="item in statusOpts"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-button type="primary" style="margin-left: 10px" @click="query">查询</el-button>
    </div>

    <div class="provider-area">
      <el-card
        shadow="always"
        class="provider-card"
        :class="provider.id == currentProvider.id ? 'active' : ''"
        v-for="provider in providers"
        :key="provider.id"
        @click.native="chooseProvider(provider)"
      >
        <i class="el-icon-edit edit" @click="editProivder(provider)"></i>
        <div class="status" :class="provider.status == 1 ? 'normal' : 'forbidden'">
          {{ provider.status == 1 ? "正常" : "封禁" }}
        </div>
        <div class="name">{{ provider.name }}</div>
        <div>
          {{ provider.staffCnt || 0 }}人
          {{ provider.scoreCnt ? provider.scoreTotal / provider.scoreCnt : 0 }}分
        </div>
        <div>
          {{ provider.serviceCnt || 0 }}次
          {{ (provider.amountPaying + provider.amountPayed) / 100 }}元
        </div>
      </el-card>
      <el-card class="provider-card" @click.native="addProvider()">
        <i class="el-icon-plus" style="margin-top: 20px; cursor: pointer"></i>
      </el-card>
    </div>

    <div class="menu">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="账户" name="first"></el-tab-pane>
        <el-tab-pane label="明细" name="second"></el-tab-pane>
        <el-tab-pane label="对账" name="third"></el-tab-pane>
      </el-tabs>
    </div>

    <div class="menu-first" v-if="activeName == 'first'">
      <provider-detail
        :provider="currentProvider"
        mode="view"
        @providerchange="providerChange"
        v-if="refresh"
      ></provider-detail>
    </div>

    <!-- 明细 -->
    <div class="menu-second" v-if="activeName == 'second'">
      <service-list
        :provider="currentProvider.id"
        :start="startDate"
        :end="endDate"
        :queryStatus="status"
        v-if="refresh"
      ></service-list>
    </div>

    <!-- 对账 -->
    <div class="menu-third" v-if="activeName == 'third'">
      <bill-list :provider="currentProvider.id" v-if="refresh"></bill-list>
    </div>

    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="showDialog"
      :title="currentProvider && currentProvider.id ? '修改服务商信息' : '新增服务商'"
    >
      <provider-detail
        :provider="currentProvider"
        mode="edit"
        @providerchange="providerChange"
        v-if="refresh"
      ></provider-detail>
    </el-dialog>
  </div>
</template>

<script>
import { getPartnerProviderList } from "@/api/partner_admin";
import serviceList from "./serviceList";
import billList from "./billList";
import providerDetail from "./provider-detail";
import { DateUtil } from "@/util";

export default {
  name: "serviceProvider",
  components: { billList, serviceList, providerDetail },
  data() {
    return {
      activeName: "first",
      startDate: null,
      endDate: null,
      status: "",
      statusOpts: [
        { label: "待办", value: "0" },
        { label: "待审", value: "1" },
        { label: "未通过", value: "2" },
        { label: "已通过", value: "3" },
      ],
      providers: [],
      currentProvider: {},
      showDialog: false,
      refresh: true,
    };
  },
  mounted() {
    this.initPage();
  },
  methods: {
    initPage() {
      this.endDate = DateUtil.format(new Date());
      this.startDate = DateUtil.format(new Date(), "yyyy-MM") + "-01";

      getPartnerProviderList({}).then((res) => {
        let data = res.data || {};
        let providers = data.providers || [];
        this.providers = providers;
        window.serviceScopeObjs = data.services || [];
        if (providers.length) {
          this.chooseProvider(providers[0]);
        }
      });
    },
    handleClick(tab) {
      this.activeName = tab.name;
    },
    chooseProvider(provider) {
      this.currentProvider = provider;
      this.refresh = false;
      this.$nextTick(() => {
        this.refresh = true;
      });
    },
    addProvider() {
      this.currentProvider = {
        id: null,
        name: null,
        telephone: null,
        logo: null,
        openTime: null,
        closeTime: null,
        lat: null,
        lng: null,
        province: null,
        city: null,
        zip: null,
        address: null,
        status: 1,
        svcScopes: [],
      };
      this.showDialog = true;
      this.refresh = false;
      this.$nextTick(() => {
        this.refresh = true;
      });
    },
    editProivder(provider) {
      this.chooseProvider(provider);
      this.showDialog = true;
    },
    providerChange(provider, changed) {
      if (changed) {
        let idx = -1;
        for (var i = 0; i < this.providers.length; i++) {
          if (this.providers[i].id == provider.id) {
            idx = i;
            break;
          }
        }
        if (idx != -1) {
          this.providers.splice(idx, 1, provider);
        } else {
          this.providers.push(provider);
        }
      }

      //如果没有provider被选中，默认选择第一个
      let cp = this.currentProvider || {};
      if (changed) {
        this.chooseProvider(provider);
      } else if (!cp.id) {
        this.chooseProvider(this.providers[0]);
      }
      this.showDialog = false;
    },
    query() {
      this.refresh = false;
      this.$nextTick(() => {
        this.refresh = true;
      });
    },
  },
};
</script>

<style lang="less">
.provider-root {
  margin-left: 10px;

  .el-dialog {
    height: 745px;
    overflow-y: scroll;
  }

  .provider-area {
    .provider-card {
      margin: 10px;
      margin-left: 0;
      height: 106px;
      width: 160px;
      text-align: center;
      display: inline-block;
      position: relative;

      &.active {
        background: #409eff;
        color: #fff;
      }

      .status {
        position: absolute;
        top: 5px;
        left: 5px;
        font-size: 12px;
        &.normal {
          color: #67c23a;
        }
        &.forbidden {
          color: #f56c6c;
        }
      }
      .edit {
        position: absolute;
        top: 5px;
        right: 5px;
        font-size: 12px;
        cursor: pointer;
      }
      .name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
</style>
