<template>
  <div class="root-box">
    <div class="condition">
      <div>
        <el-form ref="form" :inline="true" size="small" class="form">
          <el-form-item label="路线：">
            <el-select
              style="width: 150px"
              v-model="param.pathId"
              placeholder="请选择"
              clearable
              @change="query()"
            >
              <el-option
                v-for="item in pathOpts"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>

          <!-- <el-form-item label="接人/送人：">
            <el-select
              style="width: 150px"
              v-model="param.isStart"
              placeholder="请选择"
              clearable
              @change="query()"
            >
              <el-option
                v-for="item in yesNoOpts"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item> -->

          <el-form-item label="是否顺路：">
            <el-select
              style="width: 150px"
              v-model="param.isOk"
              placeholder="请选择"
              clearable
              @change="query()"
            >
              <el-option
                v-for="item in yesNoOpts"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="手机号：">
            <el-input style="width: 150px" v-model="param.userTel" />
          </el-form-item>

          <el-form-item label="开始时间：">
            <el-date-picker
              style="width: 180px"
              placeholder="选择时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              v-model="param.startTime"
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item label="结束时间：">
            <el-date-picker
              style="width: 180px"
              placeholder="选择时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              v-model="param.endTime"
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item label="分组：">
            <el-select
              style="width: 150px"
              v-model="param.checkRemark"
              filterable
              allow-create
              clearable
              default-first-option
              placeholder="请选择分组"
              @change="query()"
            >
              <el-option v-for="item in groupNameOpts" :key="item" :label="item" :value="item">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" style="margin-left: 10px; width: 100px" @click="query()"
              >查询</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>

    <div class="main-box">
      <div class="map" id="map"></div>
      <div class="right-box">
        <el-switch v-model="showDep" active-text="起点" inactive-text="终点"> </el-switch>
      </div>

      <div class="table">
        <el-table
          :data="list"
          :row-class-name="tableRowClassName2"
          :highlight-current-row="true"
          max-height="300"
          @row-click="shouMatchResult"
          size="small"
        >
          <el-table-column
            label="序号"
            type="index"
            show-overflow-tooltip
            width="50"
          ></el-table-column>

          <el-table-column prop="userTel" label="手机号" width="100"> </el-table-column>

          <el-table-column prop="seats" label="时间" width="120">
            <template slot-scope="scope">
              <div>
                {{ scope.row.order.dateTime | ticketTime }}
              </div>
            </template>
          </el-table-column>

          <el-table-column prop="seats" label="订单信息" width="100">
            <template slot-scope="scope">
              <div>
                {{ scope.row.order.date | dateFormat("MM-dd") }} &nbsp;&nbsp;
                {{ scope.row.order.number }}人
              </div>
              <div>{{ scope.row.order.pathId | enumTransfer(pathOpts) }}</div>
            </template>
          </el-table-column>

          <el-table-column prop="createTime" label="下单时间" width="120">
            <template slot-scope="scope">
              <div>
                {{ scope.row.createTime | dateFormat("MM-dd hh:mm:ss") }}
              </div>
            </template>
          </el-table-column>

          <el-table-column prop="recommend" label="推荐">
            <template slot-scope="scope">
              <div v-if="scope.row.okTickets">
                <div v-for="item in scope.row.okTickets" :key="item.id">
                  {{ item.showTime }}
                </div>
              </div>
              <div>
                {{ scope.row.remark }}
              </div>
            </template>
          </el-table-column>

          <el-table-column prop="recommend" label="重点">
            <template slot-scope="scope">
              <div v-if="!scope.row.isOk">
                <span v-if="scope.row.okTickets && !scope.row.okTickets.length">无推荐时间 </span>
                <span v-if="!scope.row.hasNoRecommendPoints">
                  <span v-if="!scope.row.recommendResult"> 没有查看推荐点</span>
                  <span v-else-if="!scope.row.recommendResult.recommendAddressPoints.length"
                    >无推荐点</span
                  >
                </span>
              </div>
              <div v-else>
                {{ scope.row.finalResult }}
              </div>
            </template>
          </el-table-column>

          <!-- <el-table-column prop="keyPointName" label="分组" width="120">
            <template slot-scope="scope">
              <el-select
                v-model="scope.row.groupName"
                size="small"
                filterable
                allow-create
                default-first-option
                placeholder="请选择分组"
                @change="(val) => groupChange(val, scope.row, scope.$index)"
              >
                <el-option v-for="item in groupNameOpts" :key="item" :label="item" :value="item">
                </el-option>
              </el-select>
            </template>
          </el-table-column> -->

          <el-table-column prop="id" label="操作" align="right">
            <template slot-scope="scope">
              <el-dropdown
                @command="(command) => handleCommand(command, scope.row)"
                style="margin-left: 10px"
                class="dropdown-meun"
              >
                <span class="el-dropdown-link">
                  操作
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="1">
                    <span>查询推荐点</span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>

        <div class="page">
          <el-pagination
            @current-change="handleCurrentChange"
            background
            :current-page="currentPage"
            :page-size="10"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>

        <div class="header">行程检测结果：</div>
        <div class="middle">
          <el-table
            :data="matchResultList"
            max-height="300"
            @row-click="showMap"
            :highlight-current-row="true"
            size="small"
          >
            <el-table-column
              label="序号"
              type="index"
              show-overflow-tooltip
              width="50"
            ></el-table-column>
            <el-table-column prop="driver" label="司机" width="100">
              <template slot-scope="scope">
                <div>
                  {{ scope.row.mongoMatchInfo.route.driverNo }}：
                  <el-link type="primary" @click.stop="showDetail(scope.row)">
                    {{
                      scope.row.ok
                        ? scope.row.result && scope.row.result.noNeedCheck
                          ? "不检测"
                          : "顺路"
                        : "不顺路"
                    }}</el-link
                  >
                </div>
              </template>
            </el-table-column>

            <el-table-column prop="remark" label="备注">
              <template slot-scope="scope">
                <div>
                  <div>{{ scope.row.remark }}</div>
                  <div v-if="scope.row.result">
                    <limit-text :width="120" :text="scope.row.result.remark"></limit-text>
                  </div>
                </div>
              </template>
            </el-table-column>

            <el-table-column prop="date" label="行程时间" width="100">
              <template slot-scope="scope">
                <div>
                  {{ scope.row.mongoMatchInfo.route.showTime }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="seats" label="剩余座位" width="80">
              <template slot-scope="scope">
                <div>{{ scope.row.mongoMatchInfo.leftSeats }} 人</div>
              </template>
            </el-table-column>

            <el-table-column prop="seats" label="预计上车时间" width="100">
              <template slot-scope="scope">
                <div v-if="scope.row.result">
                  {{ scope.row.result.getupTime | dateFormat("hh:mm") }}
                </div>
              </template>
            </el-table-column>

            <el-table-column prop="seats" label="接人中" width="80">
              <template slot-scope="scope">
                <div v-if="scope.row.result">
                  {{ scope.row.result.onGoingRoute ? "是" : "否" }}
                </div>
              </template>
            </el-table-column>

            <el-table-column prop="seqOk" label="顺序" width="80">
              <template slot-scope="scope">
                <div v-if="scope.row.result">
                  {{ scope.row.result.seqOk ? "是" : "否" }}
                </div>
              </template>
            </el-table-column>

            <el-table-column prop="isTimeOk" label="时间" width="80">
              <template slot-scope="scope">
                <div v-if="scope.row.result">
                  {{ scope.row.result.isTimeOk ? "是" : "否" }}
                </div>
              </template>
            </el-table-column>

            <el-table-column prop="isPointOk" label="顺路" width="120">
              <template slot-scope="scope">
                <div v-if="scope.row.result">
                  {{ scope.row.result.isPointOk }} - {{ scope.row.result.isDepPointOk }}/
                  {{ scope.row.result.isDestPointOk }}
                </div>
              </template>
            </el-table-column>

            <el-table-column prop="seats" label="控制级别" width="100">
              <template slot-scope="scope">
                <el-link @click="showJson(scope.row)">
                  {{
                    scope.row.mongoMatchInfo.route.controlArea | enumTransfer(controlAreaOpts)
                  }}</el-link
                >
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div class="header">测试结果：</div>
        <div class="middle">
          <el-table
            :data="testMatchResultList"
            max-height="200"
            :highlight-current-row="true"
            size="small"
          >
            <el-table-column
              label="序号"
              type="index"
              show-overflow-tooltip
              width="50"
            ></el-table-column>
            <el-table-column prop="driver" label="司机" width="100">
              <template slot-scope="scope">
                <div>
                  {{ scope.row.mongoMatchInfo.route.driverNo }}：
                  <el-link type="primary" @click.stop="showDetail(scope.row)">
                    {{
                      scope.row.ok
                        ? scope.row.result && scope.row.result.noNeedCheck
                          ? "不检测"
                          : "顺路"
                        : "不顺路"
                    }}</el-link
                  >
                </div>
              </template>
            </el-table-column>

            <el-table-column prop="seats" label="预计上车时间" width="100">
              <template slot-scope="scope">
                <div v-if="scope.row.result">
                  {{ scope.row.result.getupTime | dateFormat("hh:mm") }}
                </div>
              </template>
            </el-table-column>

            <el-table-column prop="seqOk" label="顺序" width="80">
              <template slot-scope="scope">
                <div v-if="scope.row.result">
                  {{ scope.row.result.seqOk ? "是" : "否" }}
                </div>
              </template>
            </el-table-column>

            <el-table-column prop="isTimeOk" label="时间" width="80">
              <template slot-scope="scope">
                <div v-if="scope.row.result">
                  {{ scope.row.result.isTimeOk ? "是" : "否" }}
                </div>
              </template>
            </el-table-column>

            <el-table-column prop="isPointOk" label="顺路" width="120">
              <template slot-scope="scope">
                <div v-if="scope.row.result">
                  {{ scope.row.result.isPointOk }} - {{ scope.row.result.isDepPointOk }}/
                  {{ scope.row.result.isDestPointOk }}
                </div>
              </template>
            </el-table-column>

            <el-table-column prop="remark" label="备注">
              <template slot-scope="scope">
                <div v-if="scope.row.result">
                  <limit-text :width="120" :text="scope.row.result.remark"></limit-text>
                </div>
                <div>{{ scope.row.remark }}</div>
              </template>
            </el-table-column>

            <el-table-column prop="seats" label="详情" width="100">
              <template slot-scope="scope">
                <el-link @click="showJson(scope.row)">详情</el-link>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>

    <el-drawer direction="rtl" :visible.sync="resultVisible" custom-class="drawer-modal">
      <div v-if="matchResult && matchResult.result">
        <el-form>
          <el-form-item label="时间：">
            预订：{{ matchResult.result.bookTime }} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 上车：
            {{ matchResult.result.getupTime }}
          </el-form-item>

          <el-form-item label="起点阈值：">
            {{ matchResult.result.depThreshold }} {{ matchResult.result.depExceedList }}
          </el-form-item>
          <el-form-item label="终点阈值：">
            {{ matchResult.result.destThreshold }} {{ matchResult.result.destExceedList }}
          </el-form-item>
          <el-form-item label="全行程阈值：">
            {{ matchResult.result.routeThreshold }}
          </el-form-item>

          <el-form-item label="说明：">
            {{ matchResult.result.remark }}
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="footer">
        <el-button @click.stop="resultVisible = false" size="small">关 闭</el-button>
      </span>
    </el-drawer>

    <el-drawer
      direction="rtl"
      title="查看数据"
      custom-class="drawer-modal"
      :visible.sync="jsonVisible"
      @opened="opened"
    >
      <div id="json"></div>
    </el-drawer>
  </div>
</template>

<script>
import {
  getSortExampleList,
  isTestPointOnTheWay,
  listUpriceArea,
  getRecommendPointList,
} from "@/api";
import { mapState } from "vuex";
import { DateUtil, ParamUtil, MapUtil } from "@/util";
import Router from "@/route";
import moment from "moment";

const markR = require("../../../../assets/image/mark_r.png");
const markB = require("../../../../assets/image/mark_b.png");
const markG = require("../../../../assets/image/mark_g.png");
const markY = require("../../../../assets/image/mark_y.png");
const markGrey = require("../../../../assets/image/mark_grey.png");
const markCar = require("../../../../assets/image/car_blue.png");
const markRecommend = require("../../../../assets/image/recommend.png");

export default {
  filters: {
    dateTime(val = 0) {
      const now = new Date().getTime();
      const thatTime = new Date(now + val * 1000);
      return DateUtil.format(thatTime, "yyyy-MM-dd hh:mm:ss");
    },

    ticketTime(dateTime) {
      const time = moment(dateTime);
      const nowStr = time.format("HH:mm");
      const nextHour = time.add(1, "hour");
      return `${nowStr} - ${nextHour.format("HH:mm")}`;
    },
  },
  computed: {
    ...mapState({
      size: (state) => state.btnSize,
      vehicleTypeOpts: (state) => state.vehicleTypeOpts,
      pathOpts: (state) => {
        const pathList = state.pathList;
        return pathList.map((item) => {
          return {
            label: item.name,
            value: item.id,
          };
        });
      },
    }),
  },

  watch: {
    showDep() {
      this.showMap();
    },
  },

  mounted() {
    this.initMap();
    this.query();
  },
  data() {
    return {
      yesNoOpts: [
        {
          label: "顺路",
          value: true,
        },
        {
          label: "不顺路",
          value: false,
        },
      ],
      controlAreaOpts: [
        { label: "不控制", value: 0 },
        { label: "一般", value: 1 },
        { label: "严格", value: 2 },
      ],
      param: {},
      list: [],
      resultList: [],
      groupNameOpts: ["经典案例", "问题待确认", "完美规划", "默认分组", "已查无问题", "相同问题"],
      selectItem: null,
      showName: false,
      total: 0,
      currentPage: 1,
      matchResultList: [],
      testMatchResultList: [],
      showDep: true,
      resultVisible: false,
      matchResult: null,
      jsonVisible: false,
      operParam: {},
    };
  },
  methods: {
    initMap() {
      const map = new AMap.Map("map", {
        resizeEnable: true,
        dragEnable: true,
        zoom: 14,
      });
      this.map = map;

      AMap.plugin(["AMap.PlaceSearch", "AMap.AutoComplete", "AMap.Driving", "AMap.Scale"], () => {
        const autoOptions = {
          // input 为绑定输入提示功能的input的DOM ID
          input: "tipinput",
          city: "武汉",
        };
        const autoComplete = new AMap.AutoComplete(autoOptions);
        autoComplete.on("select", this.select);

        this.driving = new AMap.Driving({
          // 驾车路线规划策略，AMap.DrivingPolicy.LEAST_TIME是最快捷模式
          policy: AMap.DrivingPolicy.LEAST_TIME,
          map: this.map,
          autoFitView: false,
          hideMarkers: true,
        });

        const scale = new AMap.Scale();
        map.addControl(scale);
      });
    },

    showRouteList(item) {
      const { href } = Router.resolve({
        path: "/route/list",
        query: { routeId: item.routeId },
      });
      window.open(href, "_blank");
    },

    query(page = 1) {
      const _param = ParamUtil.filterEmptyData(this.param);
      const param = {
        param: _param,
        startTime: _param.startTime,
        endTime: _param.endTime,
        page: {
          current: page,
          size: 10,
        },
      };
      getSortExampleList(param).then((res) => {
        const { content = [], totalElements = 0 } = res.data || {};
        this.list = content;
        this.currentPage = page;
        this.total = totalElements;
        if (content.length > 0) {
          this.shouMatchResult(content[0]);
        }
      });
    },

    handleCurrentChange(page) {
      this.query(page);
    },

    tableRowClassName({ row }) {
      return row.calcTime ? "sys" : "";
    },

    tableRowClassName2({ row }) {
      return this.selectItem && row.id === this.selectItem.id ? "sys" : "";
    },

    groupChange(val, item, index) {
      item.groupName = val;
      this.$set(this.list, index, item);
    },

    // saveRemark(item) {
    //   const param = { id: item.id, remark: item.remark };

    // },

    renderCityScope(order) {
      const { pathId } = order;
      const isStart = this.showDep;

      // 城市范围
      const pp = { pathId, isStart, areaType: 2, _useCache: true };
      listUpriceArea(pp).then((res) => {
        const scopes = res.data.map((item) =>
          MapUtil.json2Overlay(item, {
            strokeColor: "#000",
            fillColor: "transparent",
            strokeWeight: 2,
          })
        );
        scopes.forEach((item) => {
          item.on("rightclick", this.clickHandler);
        });
        this.map.add(scopes);
      });

      // 不加价区域
      const pp2 = { pathId, isStart, areaType: 3, _useCache: true };
      listUpriceArea(pp2).then((res) => {
        const scopes = res.data.map((item) =>
          MapUtil.json2Overlay(item, {
            strokeColor: "#4894ef",
            fillColor: "transparent",
            strokeWeight: 2,
          })
        );
        scopes.forEach((item) => {
          item.on("rightclick", this.clickHandler);
        });
        this.map.add(scopes);
      });
    },

    clearPath() {
      this.driving.clear();
    },

    shouMatchResult(item) {
      this.map.clearMap();
      this.clearPath();
      this._currentItem = item;
      this.matchResultList = item.matchResultList || [];
      if (this.matchResultList.length > 0) {
        this.showMap(this.matchResultList[0]);
      } else {
        this.showOrderPoint(item.order);
      }
    },

    showOrderPoint(order) {
      this.map.clearMap();
      const { depLatitude, depLongitude, destLatitude, destLongitude, dateTime, number } = order;
      const checkPointDep = new AMap.Marker({
        position: this.showDep ? [depLongitude, depLatitude] : [destLongitude, destLatitude],
        icon: new AMap.Icon({
          image: markG,
        }),
        anchor: "bottom-center",
        label: {
          direction: "top",
          content: "<div class='info'>" + `${dateTime} ${number}人` + "</div>",
        },
      });
      this.map.add(checkPointDep);
      this.map.setFitView();
    },

    showMap(matchResult) {
      if (matchResult) {
        this.matchResult = matchResult;
      }

      if (!this.matchResult) {
        return;
      }
      this.testMatchResultList = [];
      this.map.clearMap();
      this.driving.clear();

      const showDep = this.showDep;
      const { mongoMatchInfo, mongoOrder, result, routeId } = this.matchResult;
      const { depLatitude, depLongitude, destLatitude, destLongitude, dateTime } = mongoOrder;

      // 没有检测结果，直接显示订单点
      if (result == null) {
        const checkPointDep = new AMap.Marker({
          position: showDep ? [depLongitude, depLatitude] : [destLongitude, destLatitude],
          icon: new AMap.Icon({
            image: markG,
          }),
          anchor: "bottom-center",
          label: {
            direction: "top",
            content: "<div class='info'>" + `0号单 ${dateTime}` + "</div>",
          },
        });

        this.map.add(checkPointDep);
        this.map.setFitView();
        return;
      }
      const {
        depRouteResult,
        destRouteResult,
        driverPosition,
        depKeyPoint,
        destKeyPoint,
        isDepPointOk,
        isDestPointOk,
        isTimeOk,
      } = result;
      const routeResult = showDep ? depRouteResult : destRouteResult;
      const { points } = routeResult || {};
      const { recommendResult } = this._currentItem;
      this.renderCityScope(mongoOrder);

      const isPointOk = showDep ? isDepPointOk : isDestPointOk;
      const markers = [];
      const checkPointDep = new AMap.Marker({
        position: showDep ? [depLongitude, depLatitude] : [destLongitude, destLatitude],
        icon: new AMap.Icon({
          image: isPointOk ? (isTimeOk ? markG : markB) : markR,
        }),
        anchor: "bottom-center",
        label: {
          direction: "top",
          content: "<div class='info'>" + `0号单 ${dateTime}` + "</div>",
        },
      });

      markers.push(checkPointDep);

      if (driverPosition) {
        const driverNo = mongoMatchInfo.route.driverNo;
        const driverMarker = new AMap.Marker({
          position: [driverPosition.lng, driverPosition.lat],
          icon: new AMap.Icon({
            image: markCar,
            imageSize: new AMap.Size(40, 40),
          }),
          anchor: "bottom-center",
          label: {
            direction: "top",
            content: "<div class='info'>" + driverNo + "</div>",
          },
        });
        markers.push(driverMarker);
      }

      const { orders } = mongoMatchInfo;

      orders.forEach((item) => {
        const index = item.orderIndex + 1;
        const isGetUp = item.routeStatus > 2 && showDep;
        const point = new AMap.Marker({
          position: showDep
            ? [item.depLongitude, item.depLatitude]
            : [item.destLongitude, item.destLatitude],
          icon: new AMap.Icon({
            image: isGetUp ? markGrey : markY,
          }),
          anchor: "bottom-center",
          draggable: true,
          label: {
            direction: "top",
            content: "<div class='info'>" + `${index}号单 ${item.dateTime}` + "</div>",
          },
        });
        markers.push(point);
      });

      // 如果有推荐地点，展示推荐地点
      if (recommendResult && recommendResult.recommendAddressPoints) {
        recommendResult.recommendAddressPoints.forEach((item) => {
          if (item.routeId === routeId) {
            let label = item.name;
            if (item.arriveTime) {
              label += ` ${item.arriveTime}`;
            }
            const recommendPoint = new AMap.Marker({
              position: [item.lng, item.lat],
              icon: new AMap.Icon({
                image: markRecommend,
              }),
              anchor: "bottom-center",
              draggable: true,
              label: {
                direction: "top",
                content: "<div class='info'>" + `${label}` + "</div>",
              },
            });
            markers.push(recommendPoint);
          }
        });
      }

      this.map.add(markers);
      this.map.setFitView();

      if (points && points.length) {
        const mapPoints = points.map((item) => [item.lng, item.lat]);
        const size = mapPoints.length;

        // 绘制路线
        let start, end, waypoints;

        if (!showDep) {
          start = [destKeyPoint.lng, destKeyPoint.lat];
          waypoints = mapPoints.slice(0, size - 1);
          end = mapPoints[size - 1];
        } else {
          start = mapPoints[0];
          waypoints = mapPoints.slice(1, size);
          end = [depKeyPoint.lng, depKeyPoint.lat];
        }

        const opts = {
          // 途经点参数，最多支持传入16个途经点
          waypoints,
          policy: AMap.DrivingPolicy.LEAST_TIME,
        };
        this.driving.search(start, end, opts);
      }
    },

    showDetail(item) {
      this.matchResult = item;
      this.resultVisible = true;
    },

    clickHandler(ev) {
      var lnglat = ev.lnglat;
      if (!this.matchResult) {
        return;
      }
      const { mongoOrder, routeId } = this.matchResult;
      const { lat, lng } = lnglat;
      let param = {
        ...mongoOrder,
        routeId,
        onwayResultId: this._currentItem.id,
      };
      if (this.showDep) {
        param.depLatitude = lat;
        param.depLongitude = lng;
        param.departure = "测试点";
      } else {
        param.destLatitude = lat;
        param.destLongitude = lng;
        param.destination = "测试点";
      }

      isTestPointOnTheWay(param).then((res) => {
        const matchResult = res.data;
        this.testMatchResultList.push(matchResult);

        const { result } = matchResult;
        const { isPointOk, isTimeOk } = result;

        const index = this.testMatchResultList.length;
        const point = new AMap.Marker({
          position: [lng, lat],
          icon: new AMap.Icon({
            image: isPointOk ? (isTimeOk ? markG : markB) : markR,
          }),
          anchor: "bottom-center",
          label: {
            direction: "top",
            content: "<div class='info'>" + `测试${index}` + "</div>",
          },
        });

        const map = this.map;
        map.add(point);
      });
    },

    showJson(json) {
      this.jsonVisible = true;
      this.operParam = JSON.stringify(json);
    },

    opened() {
      try {
        JSON.parse(this.operParam);
        $("#json").JSONView(this.operParam, { collapsed: true });
      } catch (e) {
        this.$message.error("JSON数据不正确");
      }
    },

    handleCommand(command, item) {
      if (command === "1") {
        getRecommendPointList(item.id).then((res) => {
          const data = res.data || {};
          const { recommendAddressPoints = [] } = data;
          const markers = [];
          if (recommendAddressPoints && recommendAddressPoints.length) {
            recommendAddressPoints.forEach((item) => {
              let label = item.name;
              if (item.arriveTime) {
                label += ` ${item.arriveTime}`;
              }
              const recommendPoint = new AMap.Marker({
                position: [item.lng, item.lat],
                icon: new AMap.Icon({
                  image: markRecommend,
                }),
                anchor: "bottom-center",
                draggable: true,
                label: {
                  direction: "top",
                  content: "<div class='info'>" + `${label}` + "</div>",
                },
              });
              markers.push(recommendPoint);
            });
            this.map.add(markers);
          } else {
            this.$message.error(data.tip);
          }
        });
      }
    },
  },
};
</script>

<style scoped lang="less">
/deep/ .drawer-modal {
  width: 800px !important;
  height: 100% !important;
  padding: 0 40px;
}

.root-box {
  position: relative;
  .main-box {
    display: flex;
    height: 750px;
    position: relative;
    .right-box {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      top: 10px;
      left: 10px;
    }
    .table {
      width: 900px;
      margin-left: 10px;

      .header {
        font-size: 14px;
        margin: 10px 0 10px 5px;
      }

      .middle {
        margin-bottom: 20px;
      }
    }

    .map {
      flex: 1;
    }
  }

  .condition {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    align-items: center;
    font-weight: bold;
    padding: 5px 10px;
    position: relative;

    .el-form-item {
      margin: 10px 5px;
    }
  }
}

/deep/ .el-table .sys {
  background: oldlace;
}
</style>
