import {Http} from '@/util';

//获取合作服务商
export function getPartnerProviderList (param) {
  return Http.post('/admin/partner/provider/list', param);
}

//保存合作服务商
export function savePartnerProvider (provider) {
  return Http.post('/admin/partner/provider/save', provider);
}

//修改合作服务商状态
export function stopPartnerProviderRelation (param) {
  return Http.post('/admin/partner/provider/changestatus', param);
}



//获取服务范围list
export function getServiceScopes () {
  return Http.get('/admin/partner/service/list');
}

//获取服务项目list
export function getServiceProjs () {
  return Http.get('/admin/partner/service/listprojs');
}



//获取服务商员工
export function getPartnerStaffList (spid, param) {
  return Http.post(`/admin/partner/staff/list/${spid}`, param);
}

//服务商员工
export function savePartnerStaff (staff) {
  return Http.post('/admin/partner/staff/save', staff);
}

export function deletePartnerStaff(id) {
  return Http.get(`/admin/partner/staff/delete/${id}`);
}

export function stopPartnerStaffRelation(staff) {
  return Http.post('/admin/partner/staff/changestatus', staff);
}



export function getDriverAdminList (param) {
  return Http.post('/admin/partner/driveradmin/list', param);
}

export function saveDriverAdmin (driverAdmin) {
  return Http.post('/admin/partner/driveradmin/save', driverAdmin);
}

export function stopAdminRelation (driverAdmin) {
  return Http.post('/admin/partner/driveradmin/changestatus', driverAdmin);
}



export function listServiceBill (providerId, startDate, endDate, queryStatus) {
  if (providerId) {
    return Http.post(`/admin/partner/providerbill/list/${providerId}`, {startDate, endDate, queryStatus});
  } else {
    return Http.post(`/admin/partner/providerbill/list`, {startDate, endDate, queryStatus});
  }
}

export function auditBillStatus (billIds, providerId, status, reason) {
  return Http.post(`/admin/partner/providerbill/auditstatus`, {ids: billIds, providerId: providerId, status: status, remark: reason});
}

export function toService (billId) {
  return Http.post(`/admin/partner/providerbill/changestatus`, {id: billId, status: 3});
}

export function createServiceBillInfo (params) {
  return Http.post(`/admin/partner/providerbill/create`, params);
}

export function viewServiceBillInfo (id) {
  return Http.post(`/admin/partner/providerbill/view/${id}`);
}



export function listVehicleInfo (keyword, startDate, endDate) {
  return Http.post(`/admin/partner/vehicleinfo`, {keyword, startDate, endDate});
}

export function saveVehiclePeriodInfo (params) {
  return Http.post(`/admin/partner/vehicle/saveperiod`, params);
}



export function listBillSettle(providerId) {
  return Http.post(`/admin/partner/providersettle/list/${providerId}`, {});
}

export function uploadSettleAttach(id, photo) {
  return Http.post(`/admin/partner/providersettle/changestatus/${id}`, {photo});
}