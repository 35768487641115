import { Http } from "@/util";

// 代理排名
export function getPartnerRankList(param) {
  return Http.post("/admin/partner/rank/list", param);
}

// 红包明细
export function getPartnerHbList(param) {
  param._hideLoading = true;
  return Http.post("/admin/partner/hb/list", param);
}

// 代理详情
export function getPartnerDetail(partnerId) {
  return Http.get("/admin/partner/detail", { partnerId, _hideLoading: true });
}

// 封禁代理
export function banPartner(partnerId) {
  return Http.get("/admin/partner/ban", { partnerId });
}

// 解封代理
export function unbanPartner(partnerId) {
  return Http.get("/admin/partner/unban", { partnerId });
}

// 获取待审核代理
export function getPartnerList(param) {
  return Http.post("/admin/partner/list", param);
}

// 审核通过代理
export function passPartner(partnerId) {
  return Http.get("/admin/partner/pass", { partnerId });
}

// 删除代理
export function removePartner(partnerId) {
  return Http.get("/admin/partner/remove", { partnerId });
}

// 更新提成
export function updatePartnerIncome(param) {
  return Http.post("/admin/partner/update/income", param);
}

// 更新提成
export function deletePartnerIncome(param) {
  return Http.post("/admin/partner/del/income", param);
}

export function updatePartner(param) {
  return Http.post("/admin/partner/update", param);
}
