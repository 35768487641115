import {Http} from '@/util';


// 计算司机流水
export function calcDriverSettlement (param) {
  return Http.post('/admin/financial/calc', param);
}


// 修正司机财务账单
export function updateDriverSettlement (param) {
  return Http.post('/admin/financial/settlement/update', param);
}

// 查询司机结算
export function getSummaryList (param) {
  return Http.post('/admin/financial/settlement_summary/list', param);
}


// 审核财务账单
export function auditSummaryList (summaryIds) {
  return Http.get('/admin/financial/audit/settlement_summary', {summaryIds});
}


// 删除未审核财务账单
export function deleteSummaryList (summaryIds) {
  return Http.get('/admin/financial/del/settlement_summary', {summaryIds});
}


// 重新生成单人财务账单
export function reGenSummary (summaryId) {
  return Http.get('/admin/financial/re_gen/settlement_summary', {summaryId});
}


// 修改结算明细
export function updateSettlementDetail (detail) {
  return Http.post('/admin/financial/update/settlement_detail', detail);
}


// 查询司机日结汇总数据
export function getDailySummaryList (param) {
  return Http.post('/admin/financial/daily/settlement_summary/list', param);
}



// 查询提现结果
export function getSummaryTaskResult (summaryId) {
  return Http.get('/admin/financial/settlement_summary/task/result', {summaryId});
}

// 执行提现任务
export function execSummaryTask (taskId) {
  return Http.get('/admin/financial/do/task', {taskId});
}

// 查询transactionBill
export function getOrderTransactionBillDetail (billId) {
  return Http.get('/admin/financial/get/order_transaction_bill_detail', {billId});
}

// 查询结算明细
export function getSummaryDetailList (summaryId) {
  return Http.get('/admin/financial/detail/list', {summaryId});
}


// 新增结算明细
export function addSettlementDetail (summaryId, settlementDetail) {
  return Http.post(`/admin/financial/add/settlement_detail?summaryId=${summaryId}`, settlementDetail);
}

